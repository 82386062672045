import { AxiosResponse } from "axios";
import { BASE_URL, httpClient } from "../config/develop-config";

const reportCrmPoint = async (params: object) => {
  return await httpClient
    .post(`${BASE_URL}/crm/report-order-point/report-crm-point`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const getSearchReportCRM = async (
  company: string,
  page?: number,
  take?: number,
  searchText?: string,
) => {
  const params = {
    company: company,
    page: page,
    take: take,
    searchText: searchText,
  };
  return await httpClient
    .get(`${BASE_URL}/crm/campaign/find-campaign-for-report`, {
      params,
    })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const reportPointCampaign = async (params: object) => {
  return await httpClient
    .post(`${BASE_URL}/crm/report-order-point/report-crm-point-campaign`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const reportPointZone = async (params: object) => {
  return await httpClient
    .post(`${BASE_URL}/crm/report-order-point/report-crm-point-zone`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const reportPointProduct = async (params: object) => {
  return await httpClient
    .post(`${BASE_URL}/crm/report-order-point/report-crm-point-product`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const reportPointShop = async (params: object) => {
  return await httpClient
    .post(`${BASE_URL}/crm/report-order-point/report-crm-point-shop`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const reportProgramDetail = async (campaignProgramId: string) => {
  return await httpClient
    .get(`${BASE_URL}/crm/report-order-point/get-campaign-program-detail/${campaignProgramId}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const reportPointShopParticipate = async (params: object) => {
  return await httpClient
    .post(`${BASE_URL}/crm/report-order-point/report-shop-participate`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const exportExcelSummary = async (params: object) => {
  return await httpClient
    .post(`${BASE_URL}/crm/report-order-point/excel-report-summary`, params, {
      responseType: "arraybuffer",
    })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

export {
  reportCrmPoint,
  getSearchReportCRM,
  reportPointCampaign,
  reportPointZone,
  reportPointProduct,
  reportPointShop,
  reportProgramDetail,
  reportPointShopParticipate,
  exportExcelSummary,
};
