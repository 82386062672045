import { AxiosResponse } from "axios";
import { BASE_URL, httpClient } from "../config/develop-config";

const PreparationShipmentAll = async (params: object) => {
  return await httpClient
    .post(`${BASE_URL}/order-cart/preparation-shipment/preparation-shipment-all`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const PreparationShipmentById = async (id: string) => {
  return await httpClient
    .get(`${BASE_URL}/order-cart/preparation-shipment/preparation-shipment-by-id/${id}`)
    .then((res: AxiosResponse) => res.data.responseData)
    .catch((err) => console.log(err));
};
const PreparationShipmentHistory = async (id: string) => {
  return await httpClient
    .get(`${BASE_URL}/order-cart/preparation-shipment/preparation-shipment-history/${id}`)
    .then((res: AxiosResponse) => res.data.responseData)
    .catch((err) => console.log(err));
};
const UpdateStatusPreparationShipment = async (params: object) => {
  return await httpClient
    .post(`${BASE_URL}/order-cart/preparation-shipment/update-status-preparation-shipment`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const DownloadPDFPreparation = async (payload: {
  preparationShipmentId: string[];
}): Promise<ArrayBuffer | undefined> => {
  return await httpClient
    .post(`${BASE_URL}/order-cart/report-document/report-pdf/preparation-shipment`, payload, {
      responseType: "arraybuffer",
    })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export {
  PreparationShipmentAll,
  PreparationShipmentById,
  PreparationShipmentHistory,
  UpdateStatusPreparationShipment,
  DownloadPDFPreparation,
};
