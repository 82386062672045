import React from "react";
import styled from "styled-components";
import { color } from "../../resource";

const BaseText = styled.div<{ bgColor: string; labelColor: string }>`
  font-family: "IBM Plex Sans Thai";
  color: ${(props) => props.labelColor};
  font-size: 14px;
  font-weight: 700;
  background-color: ${(props) => props.bgColor};
  border-radius: 28px;
  padding: 0px 10px;
  width: max-content;
  height: 26px;
  display: flex;
  align-items: center;
`;

interface Props {
  label: string;
  bgColor: string;
  labelColor: string;
}

function StatusBadgeDO({ label, bgColor, labelColor }: Props) {
  return (
    <BaseText bgColor={bgColor} labelColor={labelColor}>
      {label}
    </BaseText>
  );
}

export default StatusBadgeDO;
