import React from "react";
import Text from "../../../components/Text/Text";
import { Empty, Table } from "antd";
import { BaseText } from "..";
import { dateFormatter } from "../../../utility/Formatter";
import TablePagination from "../../../components/Table/TablePagination";
import {
  STATUS_DO_COLOR,
  STATUS_DO_WORD,
  STATUS_DO_WORD_COLOR,
} from "../../../definitions/statusDO";
import StatusBadgeDO from "../../../components/Badge/StatusBadgeDO";
import { HistoryPreparationEntities } from "../../../entities/PreparationShipmentEntity";

interface Props {
  data: HistoryPreparationEntities[];
}

function HistoryTab({ data }: Props) {
  const columns = [
    {
      title: "วันที่",
      dataIndex: "createAt",
      key: "createAt",
      render: (value: string) => <BaseText>{dateFormatter(value, true)}</BaseText>,
    },
    {
      title: "สถานะ",
      dataIndex: "statusAfter",
      key: "statusAfter",
      render: (value: string) => (
        <StatusBadgeDO
          label={STATUS_DO_WORD[value]}
          bgColor={STATUS_DO_COLOR[value]}
          labelColor={STATUS_DO_WORD_COLOR[value]}
        />
      ),
    },
    {
      title: "ผู้ดำเนินการ",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (value: string) => <BaseText>{value}</BaseText>,
    },
    {
      title: "เหตุผล",
      dataIndex: "reason",
      key: "reason",
      render: (value: number, row: any) => (
        <>
          <BaseText>{value}</BaseText>
        </>
      ),
    },
  ];
  return (
    <div style={{ marginTop: 22 }}>
      <TablePagination
        columns={columns}
        data={
          data ||
          [].map((item: any, index: number) => {
            return {
              ...item,
              id: index + 1,
            };
          })
        }
        scroll={{ x: "max-content" }}
      />
    </div>
  );
}

export default HistoryTab;
