import { color } from "../resource";

export const STATUS_DO_COLOR: any = {
  WAITING: color["lightGold"],
  SUCCESS: color["lightSuccess"],
};
export const STATUS_DO_WORD_COLOR: any = {
  WAITING: color["yellow"],
  SUCCESS: color["darkGreen"],
};
export const STATUS_DO_WORD: any = {
  WAITING: "รอเตรียมสินค้า",
  SUCCESS: "เตรียมสินค้าเรียบร้อย",
};
