import React, { useEffect, useState } from "react";
import { CardContainer } from "../../components/Card/CardContainer";
import TablePagination from "../../components/Table/TablePagination";
import { Col, Row, Tabs } from "antd";
import Input from "../../components/Input/Input";
import { SearchOutlined, UnorderedListOutlined } from "@ant-design/icons";
import Text from "../../components/Text/Text";
import styled from "styled-components";
import { color } from "../../resource";
import { numberFormatter } from "../../utility/Formatter";
import SortFieldCustom from "../../components/SortFieldTable/SortFieldCustom";
import useSorting from "../../hook/useSorting";
import { useNavigate } from "react-router-dom";
import icon from "../../resource/icon";
import { truncateText } from "../../utility/truncateText";
import StatusBadgeDO from "../../components/Badge/StatusBadgeDO";
import { STATUS_DO_COLOR, STATUS_DO_WORD, STATUS_DO_WORD_COLOR } from "../../definitions/statusDO";
import { RangePicker } from "../../components/DatePicker/DatePicker";
import Button from "../../components/Button/Button";
import ModalUpdateDO from "../../components/Modal/ModalUpdateDO";
import {
  DownloadPDFPreparation,
  PreparationShipmentAll,
  UpdateStatusPreparationShipment,
} from "../../datasource/PreparationDatasource";
import {
  PreparationShipmentEntities,
  PreparationShipmentEntities_INIT,
} from "../../entities/PreparationShipmentEntity";
import moment from "moment";

export const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;

export const DescText = styled.div`
  font-family: "Sarabun";
  font-size: 14px;
  color: ${color.placeholder};
  font-weight: 400;
`;

const DeliveryDocumentList = () => {
  const [loading, setLoading] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [page, setPage] = useState(1);
  const [dateFilter, setDateFilter] = useState<any>();
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const { sortField, sortDirection, handleSortChange } = useSorting();
  const [activeTab, setActiveTab] = useState<string>("WAITING");
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<{ count: number; data: PreparationShipmentEntities[] }>({
    count: 0,
    data: [PreparationShipmentEntities_INIT],
  });
  useEffect(() => {
    getPreparation();
  }, [activeTab, searchText, page, dateFilter, sortField, sortDirection]);

  const getPreparation = async () => {
    setLoading(true);
    try {
      await PreparationShipmentAll({
        company: userProfile?.company,
        status: activeTab,
        search: searchText,
        startDate:
          dateFilter && dateFilter[0] ? dateFilter[0].format("YYYY-MM-DD 00:00:00") : undefined,
        endDate:
          dateFilter && dateFilter[1] ? dateFilter[1].format("YYYY-MM-DD 23:59:59") : undefined,
        sortField: sortDirection ? sortField : undefined,
        sortDirection: sortDirection,
        preparationShipmentId: [],
        page: page,
        take: 10,
      }).then((res) => {
        setData(res);
      });
    } catch (error) {
      console.log("err preparation shipment all", error);
    } finally {
      setLoading(false);
    }
  };
  const downloadPDF = async (preparationShipmentId: string[], soNo: string, doNo: string) => {
    try {
      const payload = {
        preparationShipmentId: Array.isArray(preparationShipmentId)
          ? preparationShipmentId
          : [preparationShipmentId],
      };
      const res = await DownloadPDFPreparation(payload);
      if (!res) {
        throw new Error("No data received for the PDF download.");
      }
      const blob = new Blob([res], { type: "application/pdf" });
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `PDO_${doNo}_${soNo}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Failed to download PDF:", error);
    }
  };
  const handleRowSelectionChange = (selectedRowKeys: string[]) => {
    setSelectedRow((prevSelectedRow) => {
      const newSelection = new Set(prevSelectedRow);
      selectedRowKeys.forEach((key) => newSelection.add(key));
      const currentPageRows = data.data.map((row) => row.preparationShipmentId);
      currentPageRows.forEach((key) => {
        if (!selectedRowKeys.includes(key)) {
          newSelection.delete(key);
        }
      });

      return Array.from(newSelection);
    });
  };
  const PageTitle = ({ searchText, setSearchText, setPage, dateFilter, setDateFilter }: any) => (
    <Row align='middle' gutter={16} justify='start' style={{ marginBottom: "20px" }}>
      <Col className='gutter-row' span={5}>
        <span
          className='card-label font-weight-bolder text-dark'
          style={{ fontSize: 24, fontWeight: "700" }}
        >
          รายการเตรียมจัดส่ง
        </span>
      </Col>
      <Col span={19}>
        <Row gutter={16} justify='end'>
          <Col span={8}>
            <Input
              placeholder='ค้นหารหัส DO / รหัส SO / ชื่อลูกค้า'
              suffix={<SearchOutlined />}
              defaultValue={searchText}
              onPressEnter={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                setSearchText(value);
                setPage(1);
              }}
              onChange={(e) => {
                const value = (e.target as HTMLInputElement).value;
                if (!value) {
                  setSearchText(undefined);
                  setPage(1);
                }
              }}
            />
          </Col>
          <Col span={6}>
            <RangePicker
              allowEmpty={[true, true]}
              enablePast
              value={dateFilter}
              onChange={(dates) => setDateFilter(dates)}
            />
          </Col>
          {selectedRow.length > 0 && activeTab === "WAITING" && (
            <Col>
              <Button title='เตรียมสินค้าเสร็จ' onClick={() => setShowModal(true)} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
  const columns: any = [
    {
      title: () => (
        <SortFieldCustom
          title='รหัส DO'
          sortDirection={sortField === "doNo " ? sortDirection : undefined}
          onSortChange={() => handleSortChange("doNo ")}
        />
      ),
      dataIndex: "doNo",
      key: "doNo",
      render: (value: any, row: any) => ({
        children: (
          <>
            <BaseText>{value || "-"}</BaseText>
            <DescText> {moment(row.createAt).format("DD/MM/YYYY, HH:mm")}</DescText>
          </>
        ),
      }),
    },
    {
      title: () => (
        <SortFieldCustom
          title='รหัส SO'
          sortDirection={sortField === "soNo" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("soNo")}
        />
      ),
      dataIndex: "soNo",
      key: "soNo",
      render: (value: any) => <BaseText>{value || "-"}</BaseText>,
    },
    {
      title: "ชื่อลูกค้า / สถานที่ส่งของ",
      dataIndex: "customerName",
      key: "customerName",
      width: "40%",
      render: (value: any, row: any) => ({
        children: (
          <>
            <BaseText style={{ fontWeight: 700 }}>{value}</BaseText>
            <DescText>{truncateText(row.shipmentAddress, 65)}</DescText>
          </>
        ),
      }),
    },
    {
      title: () => (
        <SortFieldCustom
          title='สินค้า'
          sortDirection={sortField === "amountProduct" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("amountProduct")}
        />
      ),
      dataIndex: "amountProduct",
      key: "amountProduct",
      render: (value: any) => (
        <>
          <BaseText>{numberFormatter(value, 0)}</BaseText>
          <DescText>รายการ</DescText>
        </>
      ),
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      render: (value: any, row: any) => (
        <>
          <StatusBadgeDO
            label={STATUS_DO_WORD[value]}
            bgColor={STATUS_DO_COLOR[value]}
            labelColor={STATUS_DO_WORD_COLOR[value]}
          />
          {value === "SUCCESS" && (
            <div style={{ marginTop: 4, fontSize: "12px", color: color.Text2 }}>
              <DescText>{row.updateBy}</DescText>
              <DescText> {moment(row.updateAt).format("DD/MM/YYYY, HH:mm")}</DescText>
            </div>
          )}
        </>
      ),
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      render: (value: any, row: any) => (
        <Row justify='space-between' gutter={0}>
          <Col span={12}>
            <div
              className='btn btn-icon btn-light btn-hover-primary btn-sm'
              onClick={() => navigate("/DOReport/" + row.preparationShipmentId)}
            >
              <UnorderedListOutlined style={{ color: color.primary }} />
            </div>
          </Col>
          {row.status === "WAITING" && (
            <Col span={12}>
              <div className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                <img
                  onClick={() => downloadPDF(row.preparationShipmentId, row.soNo, row.doNo)}
                  src={icon.downloadFileIcon}
                  style={{ width: 18, height: 22, color: color.BK, paddingBottom: 4 }}
                />
              </div>
            </Col>
          )}
        </Row>
      ),
    },
  ];

  return (
    <CardContainer>
      <PageTitle
        searchText={searchText}
        setSearchText={setSearchText}
        setPage={setPage}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
      />
      <Tabs
        activeKey={activeTab}
        onChange={(newActiveTab) => {
          setActiveTab(newActiveTab);
          setPage(1);
        }}
      >
        {["WAITING", "SUCCESS"].map((tabKey) => (
          <Tabs.TabPane
            tab={
              <Text fontSize={14} fontWeight={400}>
                {tabKey === "WAITING" ? "รอเตรียมสินค้า" : "เตรียมสินค้าเรียบร้อย"}
              </Text>
            }
            key={tabKey}
          >
            {selectedRow.length > 0 && tabKey === "WAITING" && (
              <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 6 }}>
                <Text fontSize={14} fontWeight={400} fontFamily='IBM Plex Sans Thai'>
                  จำนวนที่เลือก {numberFormatter(selectedRow.length, 0)} รายการ
                </Text>
              </div>
            )}

            <TablePagination
              columns={columns}
              data={data.data}
              isLoading={loading}
              scroll={{ x: "max-content" }}
              keyId='preparationShipmentId'
              isSelectRow={activeTab === "WAITING" ? true : false}
              onRowSelectionChange={handleRowSelectionChange}
              pagination={{
                current: page,
                pageSize: 10,
                total: data.count,
                onChange: (page) => setPage(page),
              }}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
      <ModalUpdateDO
        id={selectedRow}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onSubmit={async () => {
          const params = {
            preparationShipmentId: selectedRow,
            status: "SUCCESS",
            updateBy: `${userProfile.firstname} ${userProfile.lastname}`,
          };
          try {
            const res = await UpdateStatusPreparationShipment(params);
            if (res.success) {
              setTimeout(() => {
                getPreparation();
                setShowModal(false);
                setSelectedRow([]);
              }, 300);
            }
          } catch (error) {
            console.error("error update status preparation shipment:", error);
          } finally {
            setShowModal(false);
          }
        }}
      />
    </CardContainer>
  );
};

export default DeliveryDocumentList;
