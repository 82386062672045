import React, { useRef } from "react";
import { Select, Checkbox, Empty } from "antd";
import Text from "../Text/Text";
import styled from "styled-components";
import color from "../../resource/color";
import { CaretDownOutlined, CloseOutlined } from "@ant-design/icons";

const TagStyled = styled.div`
  background-color: ${color.background2};
  border-radius: 4px;
  padding: 2px 6px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  .anticon-close {
    margin-left: 8px;
    font-size: 12px;
    cursor: pointer;
    color: ${color.Disable};
  }
;`


const SelectStyled = styled(Select)`
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .ant-select-selection-overflow {
    display: inline-flex;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-select-selection-item {
    border: 1px solid ${color.primary} !important;
  }
  .ant-select-selection-placeholder {
    font-family: Sarabun !important;
  }
;`

export interface SelectOptions {
  key: any;
  value: any;
  label: string;
}
interface Props {
  selectedOptions: SelectOptions[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<SelectOptions[]>>;
  placeholder?: string;
  listOption: SelectOptions[];
  minWidth?: number;
  dropdownWidth?: number;
  maxWidth?: number;
  maxTagCount?: number;
  disable?: boolean;
  style?: React.CSSProperties;
  dropdownMaxHeight?: number;
}

const SelectCheckbox = ({
  selectedOptions,
  setSelectedOptions,
  placeholder,
  listOption,
  dropdownWidth,
  minWidth = 140,
  maxTagCount,
  maxWidth,
  disable = false,
  style,
  dropdownMaxHeight,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      setSelectedOptions(listOption);
    } else {
      setSelectedOptions([]);
    }
  };

  const handleChange = (checkedValues: any) => {
    const newSelectedOptions = listOption.filter((option) => checkedValues.includes(option.value));
    setSelectedOptions(newSelectedOptions);
  };

  const DropdownRender = ({
    listOption,
    selectedOptions,
  }: {
    listOption: SelectOptions[];
    selectedOptions: SelectOptions[];
  }) => {
    const isAllChecked = listOption.every((option) =>
      selectedOptions.some((selected) => selected.value === option.value),
    );

    return (
      <div
        style={{
          minWidth: dropdownWidth,
          maxHeight: dropdownMaxHeight,
          overflowY: "auto",
        }}
      >
        <Checkbox
          indeterminate={selectedOptions.length > 0 && selectedOptions.length < listOption.length}
          checked={isAllChecked}
          onChange={(e) => handleCheckAll(e.target.checked)}
          style={{ padding: "8px 0px 0px 12px" }}
        >
          <Text color='Text1' fontFamily='Sarabun' fontSize={16}>
            สถานะทั้งหมด
          </Text>
        </Checkbox>
        <Checkbox.Group
          value={selectedOptions.map((option) => option.value)}
          onChange={handleChange}
          style={{ display: "flex", flexDirection: "column", padding: "6px 30px" }}
        >
          {listOption.length > 0 ? (
            listOption.map((option) => (
              <Checkbox key={option.key} value={option.value}>
                <Text color='Text1' fontFamily='Sarabun' fontSize={16}>
                  {option.label}
                </Text>
              </Checkbox>
            ))
          ) : (
            <div style={{ textAlign: "center" }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={null} />
              <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text1'>
                ไม่พบรายการ
              </Text>
            </div>
          )}
        </Checkbox.Group>
      </div>
    );
  };

  return (
    <SelectStyled
      showSearch={false}
      maxTagCount={maxTagCount}
      disabled={disable}
      maxTagPlaceholder={(values) => (
        <Text fontSize={14} fontWeight={600} color='Text1' fontFamily='Sarabun'>
          {`+${values.length} รายการ`}
        </Text>
      )}
      dropdownStyle={{ minWidth: dropdownWidth, maxHeight: dropdownMaxHeight }}
      placeholder={placeholder}
      dropdownRender={() => (
        <DropdownRender listOption={listOption} selectedOptions={selectedOptions} />
      )}
      value={selectedOptions.map((option) => option.value)}
      onChange={(value: any) => {
        const newSelectedOptions = listOption.filter((option) => value.includes(option.value));
        setSelectedOptions(newSelectedOptions);
      }}
      open={isOpen}
      onDropdownVisibleChange={(open) => setIsOpen(open)}
      suffixIcon={
        <CaretDownOutlined
          style={{
            color: color.Text1,
            fontSize: "20px",
          }}
        />
      }
      style={{
        height: "40px",
        width: "100%",
        minWidth: minWidth,
        maxWidth: maxWidth,
        ...style,
      }}
      mode='multiple'
      optionFilterProp='children'
      tagRender={(props: any) => {
        if (props.isMaxTag && maxTagCount) {
          const overflowCount = selectedOptions.length - maxTagCount;
          return (
            <TagStyled>
              <Text fontSize={14} color="Text1" fontFamily="Sarabun" fontWeight={500}>
                {`+${overflowCount}`}
              </Text>
            </TagStyled>
          );
        }
        return (
          <TagRender
            {...props}
            listOption={listOption}
            onDelete={(value) =>
              setSelectedOptions((prev) => prev.filter((item) => item.value !== value))
            }
            value={props.value}
          />
        );
      }}
    ></SelectStyled>
  );
};


const TagRender = ({
  onDelete,
  value,
  listOption,
}: {
  onDelete: (value: any) => void;
  value: any;
  listOption: SelectOptions[];
}) => {
  const refTag = useRef<HTMLDivElement>(null);
  const option = listOption.find((item) => item.value === value);

  return (
    <TagStyled ref={refTag}>
      <Text fontWeight={400} fontSize={12} fontFamily="IBM Plex Sans Thai">
        {option?.label}
      </Text>
      <CloseOutlined
        onClick={() => onDelete(value)}
        style={{
          fontSize: "8px",
          marginLeft: "4px",
          cursor: "pointer",
          color: 'black'
        }}
      />
    </TagStyled>
  );
};


export default SelectCheckbox; 