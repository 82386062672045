import React, { useState, useMemo } from "react";
import { CardContainer } from "../../components/Card/CardContainer";
import Text from "../../components/Text/Text";
import { Col, Image, Row, Skeleton, Spin } from "antd";
import Button from "../../components/Button/Button";
import icon from "../../resource/icon";
import CardStatistics from "../../components/Card/CardStatistics";
import { convertBuddhistYear, formatDateRange, numberFormatter } from "../../utility/Formatter";
import { Line } from "react-chartjs-2";
import { generateChartData } from "../../utility/chartUtils";
import ModalShopList from "../../components/Modal/ReportCampaign/ModalShopList";
import color from "../../resource/color";
import Select from "../../components/Select/Select";
import { showDateText } from "../../definitions/dataFilter";
import { statisticsGraphEntities, summaryStatisticsEntities } from "../../entities/ReportCRMEntity";
import image from "../../resource/image";
import { exportExcelSummary } from "../../datasource/ReportCRMPointDatasource";
import moment from "moment";
interface ChartDataItem {
  countOrder: number;
  sumApprove: number;
  sumQuantity: number;
  sumWaitApprove: number;
}

interface Props {
  summaryStatistics: summaryStatisticsEntities;
  statisticsGraph: statisticsGraphEntities[];
  filterTarget: string[];
  typeDate: string;
  filterDay: {
    startDate: Date;
    endDate: Date;
  } | null;
  campaignProgramType: string[];
  campaignProgramCondition: string[];
  campaignId: string;
  loading: boolean;
  loadingExport: (e: boolean) => void;
}

function CampaignStatist({
  filterTarget,
  filterDay,
  typeDate,
  summaryStatistics,
  statisticsGraph,
  campaignProgramType,
  campaignProgramCondition,
  campaignId,
  loading = false,
  loadingExport,
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const checkComp = userProfile?.company === "ICLP";
  const checkTarget = filterTarget.includes("Redeem");
  const [selectedOption, setSelectedOption] = useState("overview");
  const [exporting, setExporting] = useState(false);

  const convertDate = useMemo(
    () => ({
      startDate: filterDay ? new Date(filterDay.startDate) : new Date(),
      endDate: filterDay ? new Date(filterDay.endDate) : new Date(),
    }),
    [filterDay],
  );

  const dateRange = formatDateRange(convertDate.startDate, convertDate.endDate);
  const chartData = generateChartData(convertDate, statisticsGraph, typeDate);
  const data = chartData?.data || [];
  const sumWaitApproveData = data.map((item) => item.sumWaitApprove);
  const sumQuantity = data.map((item) => item.sumQuantity);

  const getChartData = () => {
    const datasets: any[] = [];
    if (selectedOption === "overview" || selectedOption === "point") {
      datasets.push({
        label: "รอรับแต้ม",
        data: sumWaitApproveData || [],
        borderColor: "#006937",
        backgroundColor: "#BAFFDE",
        fill: true,
        borderWidth: 1,
        tension: 0.1,
        pointRadius: 3,
      });
    }

    if (selectedOption === "overview" || selectedOption === "order") {
      datasets.push({
        label: "ปริมาณการสั่งซื้อ",
        data: sumQuantity || [],
        borderColor: "#0075FF",
        backgroundColor: "#82BBFFB2",
        fill: true,
        borderWidth: 1,
        tension: 0.1,
        pointRadius: 3,
      });
    }

    return {
      labels: chartData?.labels || [],
      datasets,
    };
  };
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        backgroundColor: "white",
        borderColor: "#ccc",
        borderWidth: 1,
        padding: 10,
        cornerRadius: 5,
        callbacks: {
          title: (context) => tooltipTitle(context),
          label: (tooltipItem) => tooltipLabel(tooltipItem),
          footer: (tooltipItems) => {
            const index = tooltipItems[0].dataIndex;
            return [chartData?.labels[index] || ""];
          },
        },
        titleColor: color.Text1,
        bodyColor: color.Text1,
        footerColor: color.Text3,
        titleFont: {
          size: 14,
          weight: 700,
          family: "Sarabun",
        },
        bodyFont: {
          size: 14,
          weight: 700,
          family: "Sarabun",
        },
        footerFont: {
          size: 14,
          weight: 400,
          family: "Sarabun",
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max:
          selectedOption === "order"
            ? Math.max(...sumQuantity, 0) * 1.3
            : selectedOption === "point"
              ? Math.max(...sumWaitApproveData, 0) * 1.3
              : Math.max(Math.max(...sumWaitApproveData, 0), Math.max(...sumQuantity, 0)) * 1.3,
        ticks: { font: { family: "Sarabun, sans-serif" } },
      },
      x: {
        ticks: { font: { family: "Sarabun, sans-serif" } },
      },
    },
  };

  const tooltipTitle = (context: any) => {
    const datasetLabel = context[0].dataset.label;
    const index = context[0].dataIndex;
    const currentData = (chartData?.data[index] as ChartDataItem) || {
      countOrder: 0,
      sumWaitApprove: 0,
    };

    const order = currentData.sumQuantity;
    const points = currentData.sumWaitApprove;

    return datasetLabel === "ปริมาณการสั่งซื้อ"
      ? [`ปริมาณการสั่งซื้อ`, `${numberFormatter(order, 2)} ตัน`]
      : [`รอรับแต้ม`, `${numberFormatter(points, 2)}`];
  };

  const tooltipLabel = (tooltipItem: any) => {
    const datasetLabel = tooltipItem.dataset.label;
    const index = tooltipItem.dataIndex;
    const currentData = (chartData?.data[index] as ChartDataItem) || {
      countOrder: 0,
      sumWaitApprove: 0,
    };

    const order = currentData.countOrder;
    const points = currentData.sumApprove;

    return datasetLabel === "ปริมาณการสั่งซื้อ"
      ? [`คำสั่งซื้อ`, `${numberFormatter(order, 0)} รายการ`]
      : [`ได้รับแต้ม`, `${numberFormatter(points, 2)}`];
  };

  const renderCardStatistic = (title: string, amount: number, float: any, icon?: string) => (
    <CardStatistics
      title={title}
      period={typeDate === "Custom" ? dateRange : showDateText(typeDate)}
      amount={
        loading ? <Skeleton.Input style={{ width: 100 }} active /> : numberFormatter(amount, float)
      }
      icon={icon}
    />
  );
  const exportExcel = async () => {
    setExporting(true);
    loadingExport(true);
    try {
      const dataObject = {
        company: userProfile?.company,
        startDate: filterDay ? new Date(filterDay.endDate).toISOString() : new Date().toISOString(),
        endDate: filterDay ? new Date(filterDay.startDate).toISOString() : new Date().toISOString(),
        campaignId: campaignId,
        objective: filterTarget,
        campaignProgramType: campaignProgramType,
        campaignProgramCondition: campaignProgramCondition,
      };
      await exportExcelSummary(dataObject).then((res) => {
        const blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `Campaign-Point-Report_${convertBuddhistYear.toBuddhistYear(
          moment(filterDay?.endDate),
          "YYYY_MM_DD",
        )} ถึง ${convertBuddhistYear.toBuddhistYear(
          moment(filterDay?.startDate),
          "YYYY_MM_DD",
        )} (${userProfile?.company})`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
      setExporting(false);
    } catch (error) {
      console.log("err export excel report summary", error);
    } finally {
      setExporting(false);
      loadingExport(false);
    }
  };

  return (
    <CardContainer>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className='gutter-row' span={12}>
          <Text fontSize={18} fontWeight={700} color='Text2'>
            สถิติแคมเปญ
          </Text>
        </Col>
        <Col className='gutter-row' span={12} style={{ display: "flex", justifyContent: "end" }}>
          <Button
            disabled={loading || exporting}
            onClick={() => exportExcel()}
            title='ดาวน์โหลด'
            icon={
              <img
                src={icon.downloadCP}
                alt='download icon'
                style={{ width: "19px", height: "21px" }}
              />
            }
            style={{ width: "auto" }}
          />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16 }} style={{ marginTop: 18 }}>
        <Col className='gutter-row' span={checkComp ? 4 : 6}>
          {renderCardStatistic("แคมเปญ", summaryStatistics.countCampaign, 0)}
        </Col>
        <Col className='gutter-row' span={checkComp ? 5 : 6}>
          <CardStatistics
            title='ร้านค้า'
            period={typeDate === "Custom" ? dateRange : showDateText(typeDate)}
            amount={
              loading ? (
                <Skeleton.Input style={{ width: 100 }} active />
              ) : (
                numberFormatter(summaryStatistics.sumAllShop, 0)
              )
            }
            tooltip
            handleModal={() => setShowModal(true)}
          />
        </Col>
        <Col className='gutter-row' span={checkComp ? 5 : 6}>
          {renderCardStatistic("คำสั่งซื้อ", summaryStatistics.countOrder, 0)}
        </Col>
        {checkComp && (
          <>
            {renderCardStatistic("ปริมาณการสั่งซื้อ (กระสอบ)", summaryStatistics.sumQuantity, 2)}
            {renderCardStatistic("ปริมาณการสั่งซื้อ (กระสอบ)", summaryStatistics.sumQuantity, 2)}
          </>
        )}
        {!checkComp && (
          <Col className='gutter-row' span={6}>
            {renderCardStatistic("ปริมาณการสั่งซื้อ (ตัน)", summaryStatistics.sumQuantity, 2)}
          </Col>
        )}
      </Row>
      <Row gutter={{ xs: 8, sm: 16 }} style={{ marginTop: 18 }}>
        <Col className='gutter-row' span={checkTarget ? 6 : 8}>
          {renderCardStatistic(
            "รอรับแต้ม",
            summaryStatistics.sumPointWaitApprove,
            2,
            icon.pointPending,
          )}
        </Col>
        <Col className='gutter-row' span={checkTarget ? 6 : 8}>
          {renderCardStatistic("ได้รับแต้ม", summaryStatistics.sumPointApprove, 2, icon.getPoint)}
        </Col>
        <Col className='gutter-row' span={checkTarget ? 6 : 8}>
          {renderCardStatistic("ยกเลิกแต้ม", summaryStatistics.sumPointCancel, 2, icon.pointCancel)}
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 32 }}>
        <Col className='gutter-row' span={20}>
          <Text fontSize={18} fontWeight={700} color='Text2'>
            กราฟเทียบปริมาณการสั่งซื้อและแต้ม
          </Text>
        </Col>
        <Col className='gutter-row' span={4} style={{ display: "flex", justifyContent: "end" }}>
          <Select
            style={{ width: "100%" }}
            data={[
              { key: "overview", value: "overview", label: "ภาพรวม" },
              { key: "order", value: "order", label: "ปริมาณการสั่งซื้อ" },
              { key: "point", value: "point", label: "ปริมาณแต้ม" },
            ]}
            allowClear={false}
            defaultValue='overview'
            onChange={(e) => setSelectedOption(e)}
          />
        </Col>
      </Row>

      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px" }}
      >
        <div
          style={{
            width: "15px",
            height: "15px",
            backgroundColor: "#82BBFF99",
            border: "1px solid #0075FF",
          }}
        />
        <Text
          fontSize={12}
          style={{ marginLeft: "5px", marginRight: "10px", fontFamily: "Sarabun" }}
        >
          ปริมาณการสั่งซื้อ
        </Text>
        <div
          style={{
            width: "15px",
            height: "15px",
            backgroundColor: "#76FFBD80",
            border: "1px solid #006937",
          }}
        />
        <Text
          fontSize={12}
          style={{ marginLeft: "5px", marginRight: "10px", fontFamily: "Sarabun" }}
        >
          ปริมาณแต้ม
        </Text>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {statisticsGraph.length === 0 ? (
          <Image
            src={image.empty_reportCRM}
            style={{
              width: "100%",
              height: "100%",
            }}
            preview={false}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "350px",
              minHeight: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Skeleton style={{ width: 100 }} active />
            ) : (
              <Line data={getChartData()} options={chartOptions} />
            )}
          </div>
        )}
      </div>

      <ModalShopList
        visible={showModal}
        onCancel={() => setShowModal(false)}
        filterTarget={filterTarget}
        filterDay={filterDay}
        campaignProgramType={campaignProgramType}
        campaignProgramCondition={campaignProgramCondition}
        campaignId={campaignId}
        countCampaign={summaryStatistics.countCampaign}
        type={typeDate}
      />
    </CardContainer>
  );
}

export default CampaignStatist;
