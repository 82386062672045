import { AxiosResponse } from "axios";
import { BASE_URL, NAV_URL, httpClient } from "../config/develop-config";
import { DealerType, HistoryCampaignType } from "../definitions/campaign";

const baseUrl = `${BASE_URL}/crm`;
// const baseUrl = "http://localhost:3008";

const getCampaign = async (data: object) => {
  return await httpClient
    .post(`${baseUrl}/campaign`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getCampaignById = async (campaignId: string) => {
  return await httpClient
    .get(`${baseUrl}/campaign/${campaignId}`, { params: { campaignId } })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const createCampaign = async (data: object) => {
  return await httpClient
    .post(`${baseUrl}/campaign/create`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const updateCampaign = async (data: object) => {
  return await httpClient
    .post(`${baseUrl}/campaign/update`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const updateCampaignFile = async (data: object) => {
  return await httpClient
    .post(`${baseUrl}/campaign/update-doc`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const deleteCampaign = async (params: object) => {
  return await httpClient
    .delete(`${baseUrl}/campaign/delete`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const updateStatusCampaign = async (data: {
  campaignId: string;
  enabled?: boolean;
  approved?: boolean;
  updateBy: string;
}) => {
  return await httpClient
    .post(`${baseUrl}/campaign/update-status`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const reportOrderpointShop = async (params: any) => {
  return await httpClient
    .get(`${baseUrl}/report-order-point/report-order-point-shop`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const reportShopDetail = async (params: any) => {
  return await httpClient
    .get(`${baseUrl}/report-order-point/report-shop-detail`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const reportCampaignShop = async (params: {
  dealerId?: number;
  subDealerId?: number;
  page: number;
  take: number;
  allCampaign?: boolean;
}) => {
  return await httpClient
    .get(`${baseUrl}/report-order-point/report-campaign-shop`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const reportHistoryCampaignShop = async (params: {
  dealerId?: number;
  subDealerId?: number;
  page: number;
  take: number;
}) => {
  return await httpClient
    .get(`${baseUrl}/report-order-point/history-campaign-shop`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const reportOrderDetailShop = async (params: {
  customerCompanyId: number;
  userType: string;
  page?: number;
  take?: number;
}) => {
  return await httpClient
    .get(`${baseUrl}/report-order-point/report-order-detail`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const reportExcelShop = async (data: {
  dealerId?: number;
  subDealerId?: number;
  page?: number;
  take?: number;
}) => {
  return await httpClient
    .post(`${baseUrl}/report-order-point/excel-report-campaign-shop`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getPartner = async (userType: DealerType, id: number) => {
  const path = userType === DealerType.DEALER ? "all-sub" : "all-dealer";
  return await httpClient
    .get(`${BASE_URL}/auth/customer-partner/${path}/${id}`, {
      params: {
        subdealer_id: id,
        dealer_id: id,
      },
    })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const checkCampaignCode = async (code: string) => {
  return await httpClient
    .get(`${baseUrl}/campaign/check-campaign-code/${code}`, { params: { code } })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const trackingCampaign = async (campaignId: string) => {
  return await httpClient
    .get(`${baseUrl}/campaign/follow-campaign/${campaignId}`, { params: { campaignId } })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const trackingCampaignDetail = async (params: {
  campaignId: string;
  followCampaignType: string; // Available values : PROGRAM, SHOP, PRODUCT, REWARD
  search?: string;
  userType?: string; // Available values : DEALER, SUB_DEALER, ALL
  zone?: string;
  minQuantity?: number;
  maxQuantity?: number;
  sortBy?: string;
  sortDirection?: string; //Available values : ASC, DESC
  page?: number;
  take?: number;
}) => {
  return await httpClient
    .get(`${baseUrl}/campaign/follow-campaign-detail`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const historyCampaign = async (campaignId: string) => {
  return await httpClient
    .get(`${baseUrl}/campaign/history-campaign/${campaignId}`, { params: { campaignId } })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const historyCampaignDetail = async (params: {
  campaignProgramId: string;
  historyCampaignType: HistoryCampaignType;
  search?: string;
  zone?: string;
  sortBy?: string;
  sortDirection?: string; //ASC, DESC
  page: number;
  take: number;
}) => {
  return await httpClient
    .get(`${baseUrl}/campaign/history-campaign-detail`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const campaignShop = async (campaignId: string, userType: string, shopId: number) => {
  const params = {
    campaignId: campaignId,
    userType: userType,
    shopId: shopId,
  };
  return await httpClient
    .get(`${BASE_URL}/crm/campaign/campaign-shop`, {
      params,
    })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const campaignShopDetail = async (
  campaignId: string,
  userType: string,
  shopId: number,
  campaignShopType: string,
  sortBy?: string,
  sortDirection?: string,
  page?: number,
  take?: number,
) => {
  const params = {
    campaignId: campaignId,
    userType: userType,
    shopId: shopId,
    campaignShopType: campaignShopType,
    sortBy: sortBy,
    sortDirection: sortDirection,
    page: page,
    take: take,
  };
  return await httpClient
    .get(`${BASE_URL}/crm/campaign/campaign-shop-detail`, {
      params,
    })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

export {
  getCampaign,
  getCampaignById,
  createCampaign,
  updateCampaign,
  updateCampaignFile,
  deleteCampaign,
  reportOrderpointShop,
  reportShopDetail,
  reportCampaignShop,
  reportHistoryCampaignShop,
  reportOrderDetailShop,
  reportExcelShop,
  getPartner,
  updateStatusCampaign,
  checkCampaignCode,
  trackingCampaign,
  trackingCampaignDetail,
  historyCampaign,
  historyCampaignDetail,
  campaignShop,
  campaignShopDetail,
};
