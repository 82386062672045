import React, { useState } from "react";
import Input from "./Input";
import { Col, Row } from "antd";
import { MinusOutlined } from "@ant-design/icons";

interface MinMaxInputProps {
  initialMin?: number;
  initialMax?: number;
  onChange?: (min: number | undefined, max: number | undefined) => void;
}

const MinMaxInput: React.FC<MinMaxInputProps> = ({ initialMin = 0, initialMax = 0, onChange }) => {
  const [minValue, setMinValue] = useState<number | "">(initialMin);
  const [maxValue, setMaxValue] = useState<number | "">(initialMax);

  const isValidNumber = (value: string) => {
    const numberValue = Number(value);
    return !isNaN(numberValue) && numberValue > 0;
  };

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isValidNumber(value)) {
      const newMinValue = Number(value);
      setMinValue(newMinValue);

      if (onChange) {
        onChange(newMinValue, maxValue === "" ? undefined : maxValue);
      }
    } else if (value === "") {
      setMinValue("");
      if (onChange) {
        onChange(undefined, maxValue === "" ? undefined : maxValue);
      }
    }
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isValidNumber(value)) {
      const newMaxValue = Number(value);
      setMaxValue(newMaxValue);

      if (onChange) {
        onChange(minValue === "" ? undefined : minValue, newMaxValue);
      }
    } else if (value === "") {
      setMaxValue("");
      if (onChange) {
        onChange(minValue === "" ? undefined : minValue, undefined);
      }
    }
  };

  return (
    <Row gutter={{ xs: 8 }}>
      <Col className='gutter-row' span={10}>
        <Input
          value={minValue === 0 ? undefined : minValue}
          onChange={handleMinChange}
          placeholder='ปริมาณต่ำสุด'
        />
      </Col>
      <Col
        className='gutter-row'
        style={{ display: "flex", alignItems: "center", margin: "0px 4px" }}
      >
        <MinusOutlined />
      </Col>
      <Col className='gutter-row' span={10}>
        <Input
          value={maxValue === 0 ? undefined : maxValue}
          onChange={handleMaxChange}
          placeholder='ปริมาณสูงสุด'
        />
      </Col>
    </Row>
  );
};

export default MinMaxInput;
