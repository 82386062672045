import React, { useState } from "react";
import Text from "../../../components/Text/Text";
import styled from "styled-components";
import color from "../../../resource/color";
import { Col, Row } from "antd";
import { dateFormatter, numberFormatter } from "../../../utility/Formatter";
import TablePagination from "../../../components/Table/TablePagination";
import Button from "../../../components/Button/Button";
import { BaseText, DescText } from "..";
import icon from "../../../resource/icon";
import ModalUpdateStatusDO from "../../../components/Modal/ModalUpdateStatusDO";
import { DetailPreparationShipmentEntities } from "../../../entities/PreparationShipmentEntity";
import moment from "moment";
import { CircleDot } from "../../CRMPage/ApprovePoint/IndexApprovePoint";
import { UpdateStatusPreparationShipment } from "../../../datasource/PreparationDatasource";
import { formatPhoneNumber } from "../../../utility/formatPhoneNumber";

const DetailBox = styled.div`
  background-color: ${color.background1};
  border-radius: 10px;
  padding: 24px;
  margin: 10px 0px 22px 0px;
  border: 0.3px solid #e5eaee;
`;
const Texts = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
  word-break: break-word;
  max-width: 100%;
  white-space: normal;
`;
interface Props {
  data: DetailPreparationShipmentEntities;
  newFetch: () => void;
}

function DetailTab({ data, newFetch }: Props) {
  const [showModal, setShowModal] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile")!);

  const columns: any = [
    {
      title: "รหัส / ชื่อสินค้า",
      dataIndex: "productName",
      key: "productName",
      render: (value: any, row: any) => ({
        children: (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={row.productImage || icon.emptyProduct}
                style={{ width: "50px", height: "50px", objectFit: "cover", marginRight: "10px" }}
              />
              <div>
                <Texts>{value}</Texts>
                <Text fontFamily='Sarabun' fontSize={14} color='Text3'>
                  {row.commonName}
                </Text>
              </div>
            </div>
          </>
        ),
      }),
    },
    ...(data.status === "WAITING"
      ? [
          {
            title: "ยอดค้างส่ง",
            dataIndex: "pendingQuantity",
            key: "pendingQuantity",
            width: "15%",
            render: (value: any, row: any) => ({
              children: (
                <>
                  <BaseText>{numberFormatter(value, 2)}</BaseText>
                  <DescText>({numberFormatter(row.pendingQuantitySubUnit, 2)})</DescText>
                </>
              ),
            }),
          },
          {
            title: "เบิกสินค้า",
            dataIndex: "productRequest",
            key: "productRequest",
            width: "15%",
            render: (value: any, row: any) => ({
              children: (
                <>
                  <BaseText>{numberFormatter(value, 2)}</BaseText>
                  <DescText>({numberFormatter(row.productRequestSubUnit, 2)})</DescText>
                </>
              ),
            }),
          },
        ]
      : [
          {
            title: "จำนวน",
            dataIndex: "productRequest",
            key: "productRequest",
            width: "15%",
            render: (value: any, row: any) => ({
              children: (
                <>
                  <BaseText>{numberFormatter(value, 2)}</BaseText>
                  <DescText>({numberFormatter(row.productRequestSubUnit, 2)})</DescText>
                </>
              ),
            }),
          },
        ]),

    {
      title: "หน่วย",
      dataIndex: "unitName",
      key: "unitName",
      width: "15%",
      render: (value: any, row: any) => (
        <>
          <BaseText>{value}</BaseText>
          <DescText>{`(${row.subUnitName})`}</DescText>
        </>
      ),
    },
  ];
  return (
    <div style={{ marginTop: "22px" }}>
      <Text fontSize={18} fontWeight={700}>
        รายละเอียด
      </Text>

      <DetailBox>
        <Row gutter={[16, 16]} style={{ marginBottom: 12 }}>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={8}>
                <Texts>วันที่สั่ง :</Texts>
              </Col>
              <Col span={16} style={{ display: "flex", alignItems: "center" }}>
                <Texts style={{ marginRight: 8 }}>
                  {moment(data.orderDate).format("DD/MM/YYYY, HH:mm")}
                </Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={8}>
                <Texts>พนักงานขาย :</Texts>
              </Col>
              <Col span={16}>
                <Texts>{data.saleName}</Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={8}>
                <Texts>ชื่อลูกค้า :</Texts>
              </Col>
              <Col span={16}>
                <Texts>{data.customerName}</Texts>
                <Texts style={{ color: color.placeholder, display: "flex", alignItems: "center" }}>
                  {data.customerNo} {data.customerNo || data.customerZone ? <CircleDot /> : null}
                  {data.customerZone}
                </Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]}>
              <Col span={8}>
                <Texts>โทรศัพท์ :</Texts>
              </Col>
              <Col span={16}>
                <Texts>{formatPhoneNumber(data.customerTel)}</Texts>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12}>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={8}>
                <Texts>ที่อยู่ :</Texts>
              </Col>
              <Col span={16}>
                <Texts>{data.address || "-"}</Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={8}>
                <Texts>สถานที่ขนส่งของ :</Texts>
              </Col>
              <Col span={16}>
                <Texts>{data.shipmentAddress || "-"}</Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={8}>
                <Texts>หมายเหตุ :</Texts>
              </Col>
              <Col span={16}>
                <Texts>{data.reason || "-"}</Texts>
              </Col>
            </Row>
          </Col>
        </Row>
      </DetailBox>

      <Text fontSize={16} fontWeight={700}>
        รายการสินค้า
      </Text>
      <TablePagination
        columns={columns}
        data={
          data.preparationShipmentProduct ||
          [].map((item: any, index: number) => {
            return {
              ...item,
              id: index + 1,
            };
          })
        }
        scroll={{ x: "max-content" }}
      />
      <Button
        typeButton='primary'
        title={data.status === "WAITING" ? "เตรียมสินค้าเสร็จ" : "รอเตรียมสินค้า"}
        style={{ width: "100%" }}
        onClick={() => setShowModal(true)}
      />
      <ModalUpdateStatusDO
        visible={showModal}
        status={data.status}
        onCancel={() => setShowModal(false)}
        callBack={async (value) => {
          const newStatus = data.status === "SUCCESS" ? "WAITING" : "SUCCESS";
          if (newStatus !== "SUCCESS" && !value.reason) {
            console.error("Reason is required for status other than WAITING.");
            return;
          }
          const params = {
            preparationShipmentId: [data.preparationShipmentId],
            status: newStatus,
            ...(newStatus !== "SUCCESS" && { reason: value.reason }),
            updateBy: `${userProfile.firstname} ${userProfile.lastname}`,
          };
          try {
            const res = await UpdateStatusPreparationShipment(params);
            if (res.success) {
              newFetch();
            }
          } catch (error) {
            console.error("error update status preparation shipment:", error);
          } finally {
            setShowModal(false);
          }
        }}
      />
    </div>
  );
}

export default DetailTab;
