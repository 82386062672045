import React from "react";
import "./App.less";
import WebRoutes from "./WebRoutes";
import { RecoilRoot } from "recoil";
import { ConfigProvider } from "antd";
import BuddhaEra from "dayjs/plugin/buddhistEra";
import dayjs from "dayjs";
import { QueryClient, QueryClientProvider } from "react-query";
import isBetween from "dayjs/plugin/isBetween";
import "dayjs/locale/th";
import { GoogleOAuthProvider } from "@react-oauth/google";
dayjs.extend(BuddhaEra);
dayjs.extend(isBetween);
const googleClientId = "578439500366-b1e1acsc462351kkvvnu4d1tl2e3qvc5.apps.googleusercontent.com";
import th from "antd/es/locale/th_TH";

const locale_th: any = {
  ...th,
  Calendar: {
    ...th.Calendar,
    lang: {
      ...th.Calendar?.lang,
      yearFormat: "BBBB",
      monthFormat: "MMMM",
      monthBeforeYear: true,
    },
  },
  DatePicker: {
    ...th.DatePicker,
    lang: {
      ...th.DatePicker?.lang,
      yearFormat: "BBBB",
      monthFormat: "MMMM",
      dateFormat: "DD/MM/BBBB",
      dateTimeFormat: "DD/MM/BBBB HH:mm",
    },
  },
};
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnReconnect: true,
      },
    },
  });

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider locale={locale_th}>
          <GoogleOAuthProvider clientId={googleClientId}>
            <WebRoutes />
          </GoogleOAuthProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
