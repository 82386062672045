import React, { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { Col, Form, Row, Checkbox, Modal, Collapse, Table, Avatar, Radio, Space } from "antd";
import { color } from "../../../../resource";
import Text from "../../../../components/Text/Text";
import Input from "../../../../components/Input/Input";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import image from "../../../../resource/image";
import icon from "../../../../resource/icon";
import Button from "../../../../components/Button/Button";
import {
  CampaignProgramCondition,
  CampaignProgramType,
  DealerType,
  campaignProgramConditionLabel,
  campaignProgramTypeLabel,
} from "../../../../definitions/campaign";
import Select from "../../../../components/Select/Select";
import { CampaignProgram } from "../../../../entities/CampaignEntity";
import TableContainer from "../../../../components/Table/TableContainer";
import { StoreEntity } from "../../../../entities/StoreEntity";
import { ModalSelectStore } from "../../../Shared/ModalSelectStore";
import AddProduct from "../../../Shared/AddProduct";
import { useEffectOnce } from "react-use";
import { ProductEntity } from "../../../../entities/PoductEntity";
import { onInputNumberKeyPress } from "../../../../utility/validator";
import {
  AddingContainer,
  CollapseContainer,
  ProgramTabsContainer,
  TabBody,
  TabHeader,
} from "../ProgramComponents";
import { useRecoilState } from "recoil";
import { campaignState, step2State, tabId } from "../../../../store/createCampaign";
import { zoneDatasource } from "../../../../datasource/ZoneDatasource";
import { FlexCol } from "../../../../components/Container/Container";

export const StepTwo = ({
  form,
  programs,
  setPrograms,
}: {
  form: any;
  programs: any;
  setPrograms: (p: any) => void;
}) => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;

  const [parentState, setParentState] = useRecoilState(campaignState);
  const [state, setState] = useRecoilState(step2State);

  const [modalForm] = useForm();
  const [showModal, setModal] = useState(false);

  const [showModalStore, setShowModalStore] = useState<number>();
  const [showModalSProduct, setShowModalProduct] = useState<number>(-1);

  const [campaignProgramConditionOptions, setCampaignProgramConditionOptions] = useState<any[]>([]);

  const [zone, setZone] = useState<{ label: string; value: string; key: string }[]>([]);

  const [toBeDeleted, setDelete] = useState<number>(-1);

  const [toBeReplaced, setReplace] = useState<[number, string]>([-1, ""]);

  const defaultDuppState = {
    showModal: false,
    step: 1, // 1 => 2 (2: select dealer type => 3: select dealer) | 1 => 4 (4: select product => 5 set point)
    type: tabId.store,
    dealerType: "DL",
  };
  const [duppState, setDuppState] = useState<{
    showModal: boolean;
    step: number;
    type: tabId;
    dealerType?: string;
    products?: any[];
  }>(defaultDuppState);

  const [tempForm] = useForm();

  useEffectOnce(() => {
    if (zone.length <= 0) {
      getZoneByCompany();
    }
    // console.log({ pointData });
    // form.setFieldsValue(pointData);
  });

  const getZoneByCompany = async () => {
    const res = await zoneDatasource.getAllZoneByCompany(company);
    const data = res.map((item: any) => {
      return {
        label: item.zoneName,
        value: item.zoneName,
        key: item.zoneId,
      };
    });
    setZone(data);
  };

  const toggleModal = () => {
    setModal(!showModal);
    setCampaignProgramConditionOptions([]);
    modalForm.resetFields();
  };

  const toggleDuppliacteModal = () => {
    setDuppState({
      ...defaultDuppState,
      showModal: !duppState.showModal,
      dealerType:
        !programs[0] ||
        !programs.every((p) => p?.campaignProgramType === programs[0].campaignProgramType)
          ? (duppState.dealerType as "DL" | "SD")
          : programs[0].campaignProgramType === CampaignProgramType.ONLY_SUB_DEALER
            ? "SD"
            : "DL",
    });
  };

  const onAddProgram = () => {
    const { campaignProgramName, campaignProgramType, campaignProgramCondition } =
      modalForm.getFieldsValue();

    const now = new Date();

    const newProgramList = [
      ...programs,
      {
        campaignProgramName,
        campaignProgramType,
        campaignProgramCondition,
        tempId: `${now.getTime()}`,
      },
    ];
    setPrograms(newProgramList);
    toggleModal();
  };

  const onDeleteProgram = () => {
    setPrograms(programs.filter((p, i) => i !== toBeDeleted));
    setDelete(-1);
  };

  const setActiveKeys = (keys: string | string[]) => {
    // (programs || []).map((item: any) => item.productId),
    setState({ ...state, activeKeys: keys });
  };

  const addActiveKeyByIndex = (i: number) => {
    const key = programs[i].campaignProgramId || programs[i].tempId;
    if (Array.isArray(state.activeKeys)) {
      setActiveKeys([...state.activeKeys, key]);
    } else {
      setActiveKeys([state.activeKeys, key]);
    }
  };

  const setActiveTabs = (index: number, id: tabId) => {
    // console.log("setActiveTabs", { index, id });

    if (id === tabId.store) {
      setParentState({
        ...parentState,
        pointData: {
          ...parentState.pointData,
          ...form?.getFieldsValue(),
        },
      });
    }

    const newVal = [...state.activeTabs];
    if (state.activeTabs.length < programs.length) {
      programs.forEach((p, i) => {
        newVal[i] = newVal[i] ? newVal[i] : tabId.store;
      });
    }
    newVal[index] = id;
    setState({ ...state, activeTabs: newVal });

    // console.log(programs);
  };

  const updateProgramStore = (newStore: any, index: number, isReplace?: boolean) => {
    const newPrograms = [...programs];
    newPrograms[index] = {
      ...newPrograms[index],
      stores: isReplace ? newStore : [...(newPrograms[index].stores || []), ...newStore],
    };
    setPrograms(newPrograms);
    setShowModalStore(undefined);
    setActiveTabs(index, tabId.store);
    addActiveKeyByIndex(index);
  };

  const updateProgramProducts = (newProducts: any, index: number, isReplace?: boolean) => {
    const newPrograms = [...programs];
    newPrograms[index] = {
      ...newPrograms[index],
      products: isReplace ? newProducts : [...(newPrograms[index].products || []), ...newProducts],
    };
    setPrograms(newPrograms);
    setActiveTabs(index, tabId.product);
    addActiveKeyByIndex(index);
  };

  const replaceProduct = (newProduct: any) => {
    const newPrograms = [...programs];
    newPrograms[toBeReplaced[0]] = {
      ...newPrograms[toBeReplaced[0]],
      products: newPrograms[toBeReplaced[0]].products?.map((p) =>
        p.productId === toBeReplaced[1] ? newProduct : p,
      ),
    };
    setPrograms(newPrograms);
    setReplace([-1, ""]);
  };

  const ProgramPanelHeader = ({ i, program }: { i: number; program: CampaignProgram }) => {
    const clearError = () => {
      setState({
        ...state,
        errorStoreTabs: programs.map(() => false),
        errorProductTabs: programs.map(() => false),
      });
    };

    return (
      <Row style={{ padding: "24px 32px" }}>
        <Col span={8}>
          <Text color='Text3' level={6}>
            ชื่อพอยท์โปรแกรม
          </Text>
          <br />
          <Text color='Text1' level={5} fontWeight={700}>
            {program.campaignProgramName}
          </Text>
        </Col>
        <Col span={4}>
          <Text color='Text3' level={6}>
            รูปแบบการให้แต้ม
          </Text>
          <br />
          <Text color='Text1' level={5} fontWeight={700}>
            {campaignProgramTypeLabel[program.campaignProgramType]}
          </Text>
        </Col>
        <Col span={4}>
          <Text color='Text3' level={6}>
            เงื่อนไขการได้รับแต้ม
          </Text>
          <br />
          <Text color='Text1' level={5} fontWeight={700}>
            {campaignProgramConditionLabel[program.campaignProgramCondition]}
          </Text>
        </Col>
        <Col span={8}>
          <Row gutter={16}>
            <Col span={10}>
              <Button
                title='เพิ่มร้านค้า'
                icon={<PlusCircleOutlined />}
                typeButton='primary-light'
                onClick={() => {
                  clearError();
                  setShowModalStore(i);
                }}
              />
              {state.errorStoreTabs[i] && <Text type='danger'>โปรดเพิ่มร้านค้า</Text>}
              <ModalSelectStore
                callBackShop={(s) => updateProgramStore(s, i)}
                showModalShop={showModalStore === i}
                currentSelectShop={(program.stores as StoreEntity[]) || []}
                company={company}
                onClose={() => setShowModalStore(undefined)}
                customerType={
                  program.campaignProgramType === CampaignProgramType.ONLY_SUB_DEALER ? "SD" : "DL"
                }
              />
            </Col>
            <Col span={10}>
              <Button
                title='เพิ่มสินค้า'
                icon={<PlusCircleOutlined />}
                typeButton='primary-light'
                onClick={() => {
                  clearError();
                  setShowModalProduct(i);
                }}
              />
              {state.errorProductTabs[i] && <Text type='danger'>โปรดเพิ่มสินค้า</Text>}
            </Col>
            <Col span={4}>
              <Button
                icon={<DeleteOutlined style={{ color: color.error }} />}
                typeButton='border-light'
                onClick={() => setDelete(i)}
                style={{ padding: "8px 0px", width: 40 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const setPointProductColumns = [
    {
      title: "ชื่อสินค้า",
      dataIndex: "name",
      key: "productName",
      width: "35%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row gutter={8} style={{ padding: "12px 0px" }}>
              <Col>
                <Avatar
                  src={row.productImage ? row.productImage : image.product_no_image}
                  size={70}
                  shape='square'
                  onError={() => false}
                />
              </Col>
              <Col>
                <Text>{row.productName}</Text>
                <br />
                <Text level={6}>{row.commonName}</Text>
                <br />
                {company !== "ICPL" ? (
                  <Text level={6} color='Text3'>
                    Product Group : {row.productGroup || "-"}
                  </Text>
                ) : (
                  <Text level={6} color='Text3'>
                    Stragery Group : {row.productStrategy || "-"}
                  </Text>
                )}
              </Col>
            </Row>
          ),
        };
      },
    },
    {
      title: "",
      dataIndex: "name",
      key: "productName",
      width: "20%",
      render: (value: any, row: any, index: number) => {
        return (
          <Row>
            <Col>
              <Text>{row.company}</Text>
              <br />
              <Text level={6}>{row.commonName}</Text>
            </Col>
          </Row>
        );
      },
    },
  ];

  const ProgramPanelBody = ({ i, program }: { i: number; program: CampaignProgram }) => {
    //TODO: move tab state to parent

    const StoreTab = () => {
      const [searchStoreForm] = Form.useForm();

      const [filteredStore, setFilteredStore] = useState<StoreEntity[]>(
        program?.stores?.map((s) => ({ ...s, isChecked: false })) || [],
      );

      const onSearchStore = (values) => {
        if (program?.stores) {
          setFilteredStore(
            program?.stores
              .filter((s) => {
                if (values.search) {
                  return s?.customerName?.indexOf(values.search) > -1;
                }
                return true;
              })
              .filter((s) => {
                if (values.zone) {
                  return s?.zone === values.zone;
                }
                return true;
              }),
          );
        } else {
          setFilteredStore([]);
        }
      };

      const handleCheckBox = (e: any, customerCompanyId: number) => {
        const checkBoxed = filteredStore.map((item) => ({
          ...item,
          isChecked:
            `${item.customerCompanyId}` === `${customerCompanyId}`
              ? e.target.checked
              : item.isChecked,
        }));
        setFilteredStore(checkBoxed);
      };

      const onDelete = () => {
        Modal.confirm({
          title: "ต้องการลบร้านค้า",
          okText: "ลบ",
          cancelText: "ย้อนกลับ",
          onOk: () => {
            updateProgramStore(
              program.stores?.filter(
                (s1) =>
                  !filteredStore.find(
                    (s2) => s1.customerCompanyId === s2.customerCompanyId && s2.isChecked,
                  ),
              ),
              i,
              true,
            );
            setFilteredStore(filteredStore.filter((s) => !s.isChecked));
          },
        });
      };

      const columns = [
        {
          title: (
            <Checkbox
              onClick={() => {
                const isChecked = !filteredStore.every((x) => x.isChecked);
                setFilteredStore(filteredStore.map((item) => ({ ...item, isChecked })));
              }}
              checked={filteredStore.length > 0 && filteredStore.every((x) => x.isChecked)}
              indeterminate={
                filteredStore.find((x) => x.isChecked) && !filteredStore.every((x) => x.isChecked)
              }
            />
          ),
          width: "5%",
          dataIndex: "customerCompanyId",
          render: (customerCompanyId: any, value: any) => {
            return (
              <Checkbox
                checked={
                  !!filteredStore.find(
                    (s) => s.customerCompanyId === customerCompanyId && s.isChecked,
                  )
                }
                onClick={(e) => handleCheckBox(e, customerCompanyId)}
              />
            );
          },
        },
        {
          title: "รหัสร้านค้า",
          dataIndex: "customerNo",
          key: "customerNo",
          render: (value: any, row: any, index: number) => {
            return {
              children: <Text>{value}</Text>,
            };
          },
        },
        {
          title: "ชื่อร้านค้า",
          dataIndex: "customerName",
          key: "customerName",
          render: (value: any, row: any, index: number) => {
            return {
              children: (
                <>
                  <Text>{value}</Text>
                  <br />
                  <Text level={6} color='Text3'>
                    {row?.customer?.taxNo}
                  </Text>
                </>
              ),
            };
          },
        },
        {
          title: "ประเภทคู่ค้า",
          dataIndex: "partner",
          key: "partner",
          render: (value: any, row: any, index: number) => {
            return {
              children: (
                <Text level={6}>
                  {program.campaignProgramType === CampaignProgramType.ONLY_DEALER
                    ? "Dealer"
                    : "Subdealer"}
                </Text>
              ),
            };
          },
        },
        {
          title: "เขตการขาย",
          dataIndex: "zone",
          key: "zone",
          render: (value: any, row: any, index: number) => {
            return {
              children: <Text>{value}</Text>,
            };
          },
        },
      ];

      useEffect(() => {
        if (!state.activeTabs[i] || state.activeTabs[i] === tabId.store) {
          console.log();
        }
      }, [state.activeTabs]);

      return (
        <>
          <Form form={searchStoreForm} onValuesChange={onSearchStore}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item name='search'>
                  <Input placeholder='รหัส / ชื่อร้านค้า' suffix={<SearchOutlined />} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name='zone'>
                  <Select placeholder='เขตทั้งหมด' data={zone} style={{ width: 150 }} />
                </Form.Item>
              </Col>
              <Col span={10} />
              <Col span={3}>
                <Button
                  title='ลบร้านค้า'
                  icon={<DeleteOutlined />}
                  disabled={
                    !program.stores ||
                    program.stores.length <= 0 ||
                    !filteredStore ||
                    !filteredStore.find((s) => s.isChecked)
                  }
                  typeButton={
                    !program.stores ||
                    program.stores.length <= 0 ||
                    !filteredStore ||
                    !filteredStore.find((s) => s.isChecked)
                      ? "disabled"
                      : "border-light"
                  }
                  style={
                    !program.stores ||
                    program.stores.length <= 0 ||
                    !filteredStore ||
                    !filteredStore.find((s) => s.isChecked)
                      ? {}
                      : { color: color.error }
                  }
                  onClick={onDelete}
                />
              </Col>
            </Row>
          </Form>
          <br />
          <TableContainer>
            <Table
              columns={columns}
              dataSource={(filteredStore || program.stores)?.map((s, key) => ({ ...s, key }))}
              scroll={{ y: 600 }}
            />
          </TableContainer>
        </>
      );
    };

    const ProductTab = () => {
      const [searchProductForm] = Form.useForm();

      const [filteredProd, setFilteredProd] = useState<ProductEntity[]>(program?.products || []);

      const onSearchProduct = (values) => {
        if (program?.products) {
          setFilteredProd(
            program?.products.filter((s) => {
              if (values.search) {
                return s?.productName?.indexOf(values.search) > -1;
              }
              return true;
            }),
          );
        } else {
          setFilteredProd([]);
        }
      };

      const handleCheckBox = (e: any, productId: any) => {
        const checkBoxed = filteredProd.map((item) => ({
          ...item,
          isChecked: `${item.productId}` === `${productId}` ? e.target.checked : item.isChecked,
        }));
        setFilteredProd(checkBoxed);
      };

      const onDelete = () => {
        Modal.confirm({
          title: "ต้องการลบสินค้า",
          okText: "ลบ",
          cancelText: "ย้อนกลับ",
          onOk: () => {
            updateProgramProducts(
              program.products?.filter((s1) => {
                const notDelete = !filteredProd.find(
                  (s2) => s1.productId === s2.productId && s2.isChecked,
                );
                if (!notDelete) form.setFieldValue(`point-${i}-${s1.productId}`, "");
                return notDelete;
              }),
              i,
              true,
            );
            setFilteredProd(filteredProd.filter((s) => !s.isChecked));
          },
        });
      };

      const columns = [
        {
          title: (
            <Checkbox
              onClick={() => {
                const isChecked = !filteredProd.every((x) => x.isChecked);
                setFilteredProd(filteredProd.map((item) => ({ ...item, isChecked })));
              }}
              checked={filteredProd.length > 0 && filteredProd.every((x) => x.isChecked)}
              indeterminate={
                filteredProd.find((x) => x.isChecked) && !filteredProd.every((x) => x.isChecked)
              }
            />
          ),
          width: "3%",
          dataIndex: "productId",
          render: (productId: any, value: any) => {
            return (
              <Checkbox
                checked={!!filteredProd.find((s) => s.productId === productId && s.isChecked)}
                onClick={(e) => handleCheckBox(e, productId)}
              />
            );
          },
        },
        ...setPointProductColumns,
        {
          title: "แต้มที่ได้รับ",
          dataIndex: "productId",
          key: "point",
          width: "42%",
          render: (productId: any, row: any) => {
            return (
              <Row gutter={16} align='middle'>
                <Col span={12}>
                  <Text level={5}>
                    <img src={icon.star} style={{ width: "16px" }} />
                    &nbsp;แต้มทีได้รับ
                  </Text>
                  <Form.Item name={`point-${i}-${productId}`}>
                    <Input suffix={<Text level={5}>แต้ม</Text>} onKeyDown={onInputNumberKeyPress} />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Text level={5}>ต่อหน่วย SKU</Text>
                  <Form.Item name={`base-${i}-${productId}`} initialValue={"ตัน"}>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={5} style={{ borderLeft: "1px solid #E5EAEE" }}>
                  <Button
                    style={{ width: 40, height: 40 }}
                    typeButton='border-light'
                    icon={<EditOutlined style={{ color: color.primary }} />}
                    onClick={() => setReplace([i, productId])}
                  />
                </Col>
              </Row>
            );
          },
        },
      ];
      return (
        <>
          <Form form={searchProductForm} onValuesChange={onSearchProduct}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item name='search'>
                  <Input placeholder='รหัส / ชื่อสินค้า' suffix={<SearchOutlined />} />
                </Form.Item>
              </Col>
              <Col span={15} />
              <Col span={3}>
                <Button
                  title='ลบสินค้า'
                  icon={<DeleteOutlined />}
                  disabled={!program.products || program.products.length <= 0}
                  typeButton={
                    !program.products || program.products.length <= 0 ? "disabled" : "border-light"
                  }
                  style={
                    !program.products || program.products.length <= 0 ? {} : { color: color.error }
                  }
                  onClick={onDelete}
                />
              </Col>
            </Row>
          </Form>
          <br />
          <TableContainer>
            <Form
              // form={tableForm}
              form={form}
              // onFinish={(values) => {
              //   setPointdata(values);
              // }}
              // onValuesChange={(changedValues, allValues) => {
              //   setParentState({
              //     ...parentState,
              //     pointData: allValues,
              //   });
              // }}
            >
              <Table
                columns={columns}
                dataSource={filteredProd?.map((p) => ({ ...p, key: p.productId }))}
                scroll={{ y: 600 }}
              />
            </Form>
          </TableContainer>
        </>
      );
    };

    return (
      <ProgramTabsContainer style={{ background: color.background4 }}>
        <Row gutter={4}>
          <TabHeader
            itemId={tabId.store}
            title='ร้านค้า'
            count={program.stores?.length || 0}
            isActive={!state.activeTabs[i] || state.activeTabs[i] === tabId.store}
            setActive={() => setActiveTabs(i, tabId.store)}
          />
          <TabHeader
            itemId={tabId.product}
            title='สินค้า'
            count={program.products?.length || 0}
            isActive={state.activeTabs[i] && state.activeTabs[i] === tabId.product}
            setActive={() => setActiveTabs(i, tabId.product)}
          />
        </Row>
        <TabBody>
          {!state.activeTabs[i] || state.activeTabs[i] === tabId.store ? (
            <StoreTab />
          ) : (
            <ProductTab />
          )}
        </TabBody>
      </ProgramTabsContainer>
    );
  };

  const PanelAddBtn = ({ onClick }: { onClick: () => void }) => {
    return (
      <AddingContainer onClick={onClick} style={{ background: "white" }}>
        <img src={icon.addPointProgram} style={{ width: "80px" }} />
        <br />
        <Text level={4} color='primary'>
          <PlusOutlined />
          &nbsp;เพิ่มพอยท์โปรแกรม
        </Text>
        <Text level={5}>โปรดเพิ่มพอยท์โปรแกรมสำหรับแคมเปญ</Text>
        <Text level={5}>และต่อด้วยการเพิ่มร้านค้า, สินค้า และแต้มตามลำดับ</Text>
      </AddingContainer>
    );
  };

  const dupplicateConditionStore = (stores: any[]) => {
    // console.log("dupplicateConditionStore", stores);
    const dealerType = duppState.dealerType
      ? duppState.dealerType
      : programs[0].campaignProgramType === CampaignProgramType.ONLY_DEALER
        ? "DL"
        : "SD";
    setPrograms(
      programs.map((p) =>
        (p.campaignProgramType === CampaignProgramType.ONLY_DEALER &&
          (dealerType === DealerType.DEALER || dealerType === "DL")) ||
        (p.campaignProgramType === CampaignProgramType.ONLY_SUB_DEALER &&
          (dealerType === DealerType.SUB_DEALER || dealerType === "SD")) ||
        !duppState.dealerType
          ? {
              ...p,
              stores,
            }
          : p,
      ),
    );
    setDuppState(defaultDuppState);
  };

  const dupplicateConditionProduct = () => {
    // console.log("dupplicateConditionProduct", duppState);
    setPrograms(
      programs.map((p) => ({
        ...p,
        products: duppState?.products,
      })),
    );
    const newFormValue = {
      ...form.getFieldsValue(),
    };
    if (tempForm?.getFieldsValue() && duppState?.products) {
      for (const [key, value] of Object.entries(tempForm?.getFieldsValue())) {
        // console.log(`${key}: ${value}`);
        // in: base-${productId}
        // out: base-${i}-${productId}
        const k = key?.split("-");
        programs.forEach((e, i) => {
          // console.log(i);
          newFormValue[`${k[0]}-${i}-${k[1]}`] = value;
        });
      }
    }
    setParentState({
      ...parentState,
      pointData: {
        ...parentState.pointData,
        ...newFormValue,
      },
    });
    form.setFieldsValue(newFormValue);
    setDuppState(defaultDuppState);
  };

  const dupplicateModalFooter = () => {
    return (
      <Row justify='end' gutter={16}>
        <Col span={6}>
          <Button typeButton='primary-light' title='ยกเลิก' onClick={toggleDuppliacteModal} />
        </Col>
        <Col span={6}>
          <Button
            type='primary'
            title={[5].includes(duppState.step) ? "บันทึก" : "ถัดไป"}
            onClick={() => {
              if (duppState.step === 5) {
                dupplicateConditionProduct();
              } else {
                const next =
                  duppState.step === 1
                    ? duppState.type === tabId.store
                      ? programs.every(
                          (p) => p.campaignProgramType === programs[0].campaignProgramType,
                        )
                        ? 3
                        : 2
                      : 4
                    : duppState.step === 2
                      ? 3
                      : duppState.step === 4
                        ? 5
                        : 1;
                setDuppState({ ...duppState, step: next });
              }
            }}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row align='middle' gutter={16}>
        <Col span={16}>
          <Text level={2} fontWeight={700}>
            รายการพอยท์โปรแกรม
          </Text>
          <br />
          {programs.length <= 0 && (
            <Text level={6} color='error'>
              โปรดเพิ่มโปรแกรมอย่างน้อย 1 รายการ
            </Text>
          )}
        </Col>
        <Col span={4}>
          <Button
            title='เพิ่มเงื่อนไขเดียวกัน'
            typeButton={programs?.length <= 0 ? "disabled" : "primary-light"}
            style={{ fontSize: 14 }}
            textStyle={{ fontSize: 14 }}
            onClick={toggleDuppliacteModal}
            size='large'
            disabled={programs?.length <= 0}
          />
        </Col>
        <Col span={4}>
          <Button
            title='เพิ่มพอยท์โปรแกรม'
            typeButton='primary'
            style={{ fontSize: 14 }}
            textStyle={{ fontSize: 14 }}
            icon={<PlusOutlined />}
            onClick={toggleModal}
            size='large'
          />
        </Col>
      </Row>
      <br />
      {programs.length <= 0 ? (
        <PanelAddBtn onClick={toggleModal} />
      ) : (
        <>
          <CollapseContainer>
            <Collapse
              defaultActiveKey={programs.map((p) => p.campaignProgramId || p.tempId)}
              activeKey={state?.activeKeys}
              collapsible='icon'
              onChange={setActiveKeys}
              expandIconPosition='end'
              expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
            >
              {programs.map((prog: CampaignProgram, i) => {
                return (
                  <>
                    <Collapse.Panel
                      header={<ProgramPanelHeader i={i} program={prog} />}
                      key={prog.campaignProgramId || prog.tempId || i}
                    >
                      <ProgramPanelBody i={i} program={prog} />
                    </Collapse.Panel>
                  </>
                );
              })}
            </Collapse>
          </CollapseContainer>
          <Modal
            open={toBeDeleted > -1}
            okText='ยืนยัน'
            okButtonProps={{
              style: { color: "white", backgroundColor: "red", border: "none" },
            }}
            onOk={onDeleteProgram}
            cancelText='ยกเลิก'
            cancelButtonProps={{ style: { borderColor: "red", color: "red" } }}
            onCancel={() => setDelete(-1)}
            closable={false}
          >
            <Text level={3} fontWeight={700}>
              ต้องการลบรายการพอยท์โปรแกรม
            </Text>
            <div style={{ paddingTop: 8 }}>
              <Text level={5}>โปรดยืนยันการลบรายการ</Text>
            </div>
          </Modal>
        </>
      )}
      <Modal
        open={showModal}
        onCancel={toggleModal}
        footer={
          <Row justify='end' gutter={16}>
            <Col span={6}>
              <Button typeButton='primary-light' title='ยกเลิก' onClick={toggleModal} />
            </Col>
            <Col span={6}>
              <Button type='primary' title='บันทึก' onClick={() => modalForm.submit()} />
            </Col>
          </Row>
        }
      >
        <Text level={4} fontWeight={700}>
          เพิ่มพอยท์โปรแกรม
        </Text>
        <br />
        <Form form={modalForm} layout='vertical' onFinish={onAddProgram}>
          <Form.Item
            label='ชื่อพอยท์โปรแกรม'
            name='campaignProgramName'
            rules={[{ required: true, message: "โปรดชื่อระบุพอยท์โปรแกรม" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='รูปแบบการให้แต้มกับร้านค้า'
            name='campaignProgramType'
            rules={[{ required: true, message: "โปรดเลือกรูปแบบการให้แต้มกับร้านค้า" }]}
          >
            <Select
              data={[CampaignProgramType.ONLY_DEALER, CampaignProgramType.ONLY_SUB_DEALER].map(
                (t) => ({ label: campaignProgramTypeLabel[t], value: t, key: t }),
              )}
              onChange={(value) => {
                setCampaignProgramConditionOptions(
                  value === CampaignProgramType.ONLY_DEALER
                    ? company === "ICPL"
                      ? [CampaignProgramCondition.CONFIRM_RECEIVED]
                      : [CampaignProgramCondition.DELIVERY_SUCCESS]
                    : [CampaignProgramCondition.SUBMIT_DELIVERY_DOC],
                );
                modalForm.setFieldValue("campaignProgramCondition", null);
              }}
            />
          </Form.Item>
          <Form.Item
            label='เงื่อนไขการได้รับแต้ม'
            name='campaignProgramCondition'
            rules={[{ required: true, message: "โปรดเลือกเงื่อนไขการได้รับแต้ม" }]}
          >
            <Select
              data={campaignProgramConditionOptions.map((t) => ({
                label: campaignProgramConditionLabel[t],
                value: t,
                key: t,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={showModalSProduct > -1 || (toBeReplaced[0] > -1 && !!toBeReplaced[1])}
        width={1350}
        closable={false}
        footer={null}
      >
        <AddProduct
          list={
            (toBeReplaced[0] > -1
              ? programs[toBeReplaced[0]]?.products
              : showModalSProduct > -1
                ? programs[showModalSProduct]?.products
                : []) || []
          }
          setList={
            toBeReplaced[0] > -1
              ? (p) => replaceProduct(p)
              : (s) => updateProgramProducts(s, showModalSProduct)
          }
          onClose={() => {
            setShowModalProduct(-1);
            setReplace([-1, ""]);
          }}
          isReplacing={toBeReplaced[0] > -1}
        />
      </Modal>

      {/* START: เพิ่มเงื่อนไขเดียวกัน */}
      <Modal
        open={duppState.showModal && ![3, 4].includes(duppState.step)}
        onCancel={toggleDuppliacteModal}
        footer={dupplicateModalFooter()}
        width={duppState.step === 5 ? 1350 : 520}
      >
        <Text level={4} fontWeight={700}>
          เพิ่มเงื่อนไขเดียวกัน
        </Text>
        <br />
        <br />
        <Text fontWeight={700}>
          ขั้นตอน&nbsp;
          {duppState.step === 1
            ? 1
            : duppState.step > 3
              ? duppState.step - 2
              : programs.every((p) => p.campaignProgramType === programs[0].campaignProgramType)
                ? duppState.step - 1
                : duppState.step}
          /
          {duppState.type === tabId.store &&
          programs.every((p) => p.campaignProgramType === programs[0].campaignProgramType)
            ? 2
            : 3}
          {/* STEPS NO
          1. เพิ่มร้านค้า -> 2,3 | เพิ่มสินค้า  -> 4
          2. รูปแบบการให้แต้มกับร้านค้า -> 3 //if (only 1 dealer type) then skip 
             //programs.every(p => p.campaignProgramType === programs[0].campaignProgramType)
          3. เลือกร้านค้า
          4. ขั้นตอน 2/3 : เลือกสินค้า
          5. ขั้นตอน 3/3 : กำหนดแต้ม          
        */}
        </Text>
        {duppState.step === 5 ? (
          <TableContainer>
            <Form form={tempForm}>
              <Table
                columns={[
                  ...setPointProductColumns,
                  {
                    title: "แต้มที่ได้รับ",
                    dataIndex: "productId",
                    key: "point",
                    width: "42%",
                    render: (productId: any, row: any) => {
                      return (
                        <Row gutter={16} align='middle'>
                          <Col span={12}>
                            <Text level={5}>
                              <img src={icon.star} style={{ width: "16px" }} />
                              &nbsp;แต้มทีได้รับ
                            </Text>
                            <Form.Item name={`point-${productId}`}>
                              <Input
                                suffix={<Text level={5}>แต้ม</Text>}
                                onKeyDown={onInputNumberKeyPress}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Text level={5}>ต่อหน่วย SKU</Text>
                            <Form.Item name={`base-${productId}`} initialValue={"ตัน"}>
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    },
                  },
                ]}
                dataSource={duppState.products}
                scroll={{ y: 400 }}
                pagination={false}
              />
            </Form>
          </TableContainer>
        ) : duppState.step === 2 ? (
          <>
            <div style={{ marginTop: 8 }}>
              <Text level={6}>
                รูปแบบการให้แต้มกับร้านค้า <span style={{ color: "red" }}>*</span>
              </Text>
              <br />
              <Radio.Group
                value={duppState.dealerType}
                onChange={(e) =>
                  setDuppState({
                    ...duppState,
                    dealerType: e.target.value,
                  })
                }
              >
                <Space direction='vertical'>
                  {[
                    {
                      value: "DL",
                      label: campaignProgramTypeLabel.ONLY_DEALER,
                    },
                    {
                      value: "SD",
                      label: campaignProgramTypeLabel.ONLY_SUB_DEALER,
                    },
                  ].map(({ value, label }) => (
                    <Radio value={value} key={value}>
                      {label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>
          </>
        ) : (
          <div style={{ marginTop: 8 }}>
            <Text level={6}>
              รูปแบบการเพิ่มเงื่อนไขเดียวกัน <span style={{ color: "red" }}>*</span>
            </Text>
            <br />
            <Radio.Group
              value={duppState.type}
              onChange={(e) => setDuppState({ ...duppState, type: e.target.value })}
            >
              <Space direction='vertical'>
                {[
                  {
                    value: tabId.store,
                    label: "เพิ่มร้านค้า",
                    key: tabId.store,
                  },
                  {
                    value: tabId.product,
                    label: "เพิ่มสินค้า",
                    key: tabId.product,
                  },
                ].map(({ value, label }) => (
                  <Radio value={value} key={value}>
                    {label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </div>
        )}
      </Modal>
      <ModalSelectStore
        showModalShop={duppState.step === 3}
        callBackShop={(s) => dupplicateConditionStore(s)}
        currentSelectShop={[]}
        company={company}
        onClose={() => setDuppState(defaultDuppState)}
        replaceTitle={
          <FlexCol>
            <Text level={4} fontWeight={700}>
              เพิ่มเงื่อนไขเดียวกัน
            </Text>
            <br />
            <br />
            <Text fontWeight={700}>ขั้นตอน&nbsp;3/3</Text>
          </FlexCol>
        }
        customerType={
          !programs[0] ||
          !programs.every((p) => p?.campaignProgramType === programs[0].campaignProgramType)
            ? (duppState.dealerType as "DL" | "SD")
            : programs[0].campaignProgramType === CampaignProgramType.ONLY_SUB_DEALER
              ? "SD"
              : "DL"
        }
      />
      <Modal open={duppState.step === 4} width={1350} closable={false} footer={null}>
        <AddProduct
          list={[]}
          setList={(products) => {
            setDuppState({
              ...duppState,
              step: 5,
              products,
              showModal: true,
            });
          }}
          customTitle={
            <FlexCol>
              <Text level={4} fontWeight={700}>
                เพิ่มเงื่อนไขเดียวกัน
              </Text>
              <br />
              <Text fontWeight={700}>ขั้นตอน&nbsp;2/3</Text>
            </FlexCol>
          }
          withBackButton={{
            title: "ย้อนกลับ",
            onBack: () => {
              setDuppState({ ...duppState, step: 1 });
            },
          }}
          saveButtonTitle='ถัดไป'
        />
      </Modal>
      {/* END: เพิ่มเงื่อนไขเดียวกัน */}
    </>
  );
};
