import React, { useEffect, useState } from "react";
import PageTitleNested from "../../../../components/PageTitle/PageTitleNested";
import { useLocation, useNavigate } from "react-router-dom";
import { FlexCol } from "../../../../components/Container/Container";
import Text from "../../../../components/Text/Text";
import BreadCrumb from "../../../../components/BreadCrumb/BreadCrumb";
import { CardContainer } from "../../../../components/Card/CardContainer";
import Button from "../../../../components/Button/Button";
import { DownloadOutlined } from "@ant-design/icons";
import ParticipateCampaign from "./ParticipateCampaign";
import { Divider, Tabs } from "antd";
import TabListTable from "./TabListTable";
import { campaignShop, campaignShopDetail } from "../../../../datasource/CampaignDatasource";
import {
  CampaignShopEntities,
  CampaignShopEntities_INIT,
  OrderEntities,
  ProductEntities,
  ProgramEntities,
} from "../../../../entities/CampaignShopEntity";
import useSorting from "../../../../hook/useSorting";
import icon from "../../../../resource/icon";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "../../../../config/develop-config";

function CRMShopDetail() {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState<"ORDER" | "PRODUCT" | "PROGRAM">("ORDER");
  const location = useLocation();
  const { row, campaignId } = location.state || {};
  const [participateCampData, setParticipateCampData] =
    useState<CampaignShopEntities>(CampaignShopEntities_INIT);

  const [loadingExcel, setLoadingExcel] = useState(false);

  useEffect(() => {
    const fetchCampShopData = async () => {
      setLoading(true);
      try {
        await campaignShop(campaignId, row?.shopType, row?.shopId).then((res) => {
          setParticipateCampData(res);
          setLoading(false);
        });
      } catch (error) {
        console.log("err crm/campaign/campaign-shop", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCampShopData();
  }, []);

  const onDownloadExcel = async () => {
    setLoadingExcel(true);
    const headers = { "Content-Type": "blob" };
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${BASE_URL}/crm/campaign/excel-report-campaign-shop-detail`,
      responseType: "arraybuffer",
      headers,
      data: {
        campaignId,
        shopId: row?.shopId,
        userType: row?.shopType,
        company,
      },
    };
    const response = await axios(config);

    const file = new Blob([response.data], {
      type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `report-campaign-shop-detail-${campaignId}-${new Date().toISOString().split("T")[0]}.xlsx`,
    );

    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);

    setLoadingExcel(false);
  };

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={`ร้านค้า : ${participateCampData.shopName}`}
        showBack
        onBack={() => navigate(-1)}
        extra={
          <FlexCol align='end'>
            <Button
              title='ดาวน์โหลด'
              icon={<img src={icon.downloadFileIcon} style={{ width: 14, height: 18 }} />}
              typeButton='primary-light'
              onClick={onDownloadExcel}
              loading={loadingExcel}
            />
          </FlexCol>
        }
        customBreadCrumb={
          <BreadCrumb
            data={[
              {
                text: "รายการแคมเปญพอยท์",
                path: "/crm/campaignPoint",
              },
              { text: "รายละเอียดแคมเปญพอยท์", path: -1 },
              { text: "รายละเอียดร้านค้า", path: -1 },
            ]}
          />
        }
      />
    );
  };
  const tabsData: { key: string; label: React.ReactNode; children?: JSX.Element | undefined }[] = [
    {
      key: "ORDER",
      label: (
        <Text fontSize={14} fontWeight={400}>
          รายการคำสั่งซื้อ
        </Text>
      ),
    },
    {
      key: "PROGRAM",
      label: (
        <Text fontSize={14} fontWeight={400}>
          พอยท์โปรแกรม
        </Text>
      ),
    },
    {
      key: "PRODUCT",
      label: (
        <Text fontSize={14} fontWeight={400}>
          ยอดสินค้าที่สั่งซื้อ
        </Text>
      ),
    },
  ];
  const handleTabChange = (key: "ORDER" | "PRODUCT" | "PROGRAM") => {
    setTabs(key);
  };

  return (
    <CardContainer>
      <PageTitle />
      <Divider style={{ marginTop: 50 }} />
      <ParticipateCampaign type={row?.shopType} data={participateCampData} loading={loading} />
      <Tabs items={tabsData} onChange={(e: any) => handleTabChange(e)} />
      <TabListTable
        tabs={tabs}
        type={row?.shopType}
        campaignId={campaignId}
        shopType={row?.shopType}
        shopId={row?.shopId}
      />
    </CardContainer>
  );
}

export default CRMShopDetail;
