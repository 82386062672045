export enum AdditionalRole {
  SINGLE_ZONE = "SINGLE_ZONE",
  MULTI_ZONE = "MULTI_ZONE",
  NAV_DOC = "NAV_DOC",
  NONE = "NONE",
}

export const additionalRoleLabel = {
  SINGLE_ZONE: "ดูแลแบบเขตเดียว",
  MULTI_ZONE: "ดูแลแบบหลายเขต",
  NAV_DOC: "ประเภทเอกสาร NAV",
  NONE: "ไม่มี",
};
