import { Empty, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import Text from "../Text/Text";
import TableSecondary from "../Table/TableSecondary";
import styled from "styled-components";
import color from "../../resource/color";
import { truncateText } from "../../utility/truncateText";
import { numberFormatter } from "../../utility/Formatter";
import StatusBadgeDO from "../Badge/StatusBadgeDO";
import { STATUS_DO_COLOR, STATUS_DO_WORD, STATUS_DO_WORD_COLOR } from "../../definitions/statusDO";
import icon from "../../resource/icon";
import Button from "../Button/Button";
import {
  PreparationShipmentEntities,
  PreparationShipmentEntities_INIT,
} from "../../entities/PreparationShipmentEntity";
import {
  PreparationShipmentAll,
  UpdateStatusPreparationShipment,
} from "../../datasource/PreparationDatasource";
import moment from "moment";

interface Props {
  id: any[];
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

const CustomTable = styled.div`
  .ant-table-thead > tr > th {
    background-color: ${color.secondary};
    color: ${color.white};
    font-family: "IBM Plex Sans Thai";
    font-weight: 600;
    font-size: 16px;
    text-align: start;
  }

  .ant-table-tbody > tr > td {
    font-family: "Sarabun";
    font-size: 16px;
    font-weight: 400;
    color: ${color.Text1};
    padding: 16px;
    text-align: start;
  }

  .ant-table-tbody > tr:nth-child(odd) {
    background-color: ${color.white};
  }

  .ant-table {
    border-radius: 8px;
    overflow: hidden;
    border: 0.3px solid ${color.placeholder};
  }
`;

const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;

const DescText = styled.div`
  font-family: "Sarabun";
  font-size: 14px;
  color: ${color.placeholder};
  font-weight: 400;
`;

function ModalUpdateDO({ id, visible, onCancel, onSubmit }: Props) {
  const [loading, setLoading] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<{ count: number; data: PreparationShipmentEntities[] }>({
    count: 0,
    data: [PreparationShipmentEntities_INIT],
  });

  useEffect(() => {
    if (visible) {
      getPreparation(true);
    }
  }, [visible]);

  const getPreparation = async (firstTime: boolean) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const res =
          data.count === data.data.length && data.count !== 0
            ? { count: data.count, data: [] }
            : await PreparationShipmentAll({
                company: userProfile?.company,
                preparationShipmentId: id,
                page: page,
                take: 10,
              });
        if (!firstTime) {
          setData({
            data: [...data.data, ...res.data],
            count: res.count,
          });
          setPage(page + 1);
        } else {
          setData(res);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }, 500);
  };

  const columns: any = [
    {
      title: "รหัส DO",
      dataIndex: "doNo",
      key: "doNo",
      render: (value: any, row: any) => ({
        children: (
          <>
            <BaseText>{value || "-"}</BaseText>
            <DescText> {moment(row.orderDate).format("DD/MM/YYYY,HH:mm")}</DescText>
          </>
        ),
      }),
    },
    {
      title: "รหัส SO",
      dataIndex: "soNo",
      key: "soNo",
      render: (value: any) => <BaseText>{value || "-"}</BaseText>,
    },
    {
      title: "ชื่อลูกค้า / สถานที่ส่งของ",
      dataIndex: "customerName",
      key: "customerName",
      width: "40%",
      render: (value: any, row: any) => ({
        children: (
          <>
            <BaseText style={{ fontWeight: 700 }}>{value}</BaseText>
            <DescText>{truncateText(row.shipmentAddress, 65)}</DescText>
          </>
        ),
      }),
    },
    {
      title: "สินค้า",
      dataIndex: "amountProduct",
      key: "amountProduct",
      render: (value: any) => (
        <>
          <BaseText>{numberFormatter(value, 0)}</BaseText>
          <DescText>รายการ</DescText>
        </>
      ),
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      render: (value: any, row: any) => (
        <>
          <StatusBadgeDO
            label={STATUS_DO_WORD[value]}
            bgColor={STATUS_DO_COLOR[value]}
            labelColor={STATUS_DO_WORD_COLOR[value]}
          />
          {value === "SUCCESS" && (
            <div style={{ marginTop: 4, fontSize: "12px", color: color.Text2 }}>
              <DescText>{row.user}</DescText>
              <DescText>{row.time}</DescText>
            </div>
          )}
        </>
      ),
    },
  ];

  const handleLoadMore = (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    const bottom = target.scrollHeight === target.scrollTop + target.clientHeight;
    if (bottom && data.data.length < data.count && !loading) {
      getPreparation(false);
    }
  };

  return (
    <Modal
      width={1200}
      open={visible}
      closable
      onCancel={onCancel}
      title={
        <Text fontWeight={700} fontSize={20}>
          รายการที่เลือกทั้งหมด
        </Text>
      }
      centered
      footer={null}
    >
      <CustomTable>
        <Text fontWeight={400} fontSize={14} style={{ marginBottom: 4 }}>
          จำนวนที่เลือก {numberFormatter(data.count, 0)} รายการ
        </Text>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data.data}
          pagination={false}
          onScroll={handleLoadMore}
          scroll={{ y: 500 }}
          locale={{
            emptyText: (
              <div style={{ display: "flex", justifyContent: "center", padding: "6%" }}>
                <Empty
                  image={icon.emptyHistoryPoint}
                  description={
                    <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text3'>
                      ไม่มีรายการ
                    </Text>
                  }
                />
              </div>
            ),
          }}
        />
      </CustomTable>
      <div
        style={{
          display: "flex",
          gap: "16px",
          padding: "16px 0px",
          justifyContent: "flex-end",
          borderTop: "1px solid #E5E5E5",
        }}
      >
        <Button
          style={{ width: "100px" }}
          height={40}
          title='ยกเลิก'
          typeButton='primary-light'
          onClick={onCancel}
        />
        <Button style={{ width: "100px" }} height={40} title='ยืนยัน' onClick={onSubmit} />
      </div>
    </Modal>
  );
}

export default ModalUpdateDO;
