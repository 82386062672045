import React, { useEffect, useState } from "react";
import Text from "../../../../components/Text/Text";
import { Empty, Table } from "antd";
import styled from "styled-components";
import color from "../../../../resource/color";
import StatusBadge from "../../../../components/Badge/StatusBadge";
import {
  MAP_STATUS_COLOR,
  MAP_STATUS_WORD,
  MAP_USER_TYPE,
} from "../../../../definitions/approvePoint";
import { dateFormatter, numberFormatter } from "../../../../utility/Formatter";
import { getPointHistory } from "../../../../datasource/OrderPointDatasource";
import icon from "../../../../resource/icon";
import { PointHistoryEntity } from "../../../../entities/PointApproveEntity";

const CustomTableWrapper = styled.div`
  .ant-table-thead > tr > th {
    background-color: ${color.secondary};
    color: ${color.white};
    font-family: "IBM Plex Sans Thai";
    font-weight: 600;
    font-size: 16px;
    text-align: start;
  }

  .ant-table-tbody > tr > td {
    font-family: "Sarabun";
    font-size: 16px;
    font-weight: 400;
    color: ${color.Text1};
    padding: 16px;
    text-align: start;
  }

  .ant-table-tbody > tr:nth-child(odd) {
    background-color: ${color.white};
  }

  .ant-table {
    border-radius: 8px;
    overflow: hidden;
    border: 0.3px solid ${color.placeholder};
  }
`;

const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;

const DescText = styled.div`
  font-family: "Sarabun";
  font-size: 14px;
  color: ${color.placeholder};
  font-weight: 400;
`;

interface Props {
  data: PointHistoryEntity[];
}
function HistoryTab({ data }: Props) {
  const columns = [
    {
      title: "วันเวลาอัปเดท",
      dataIndex: "updateAt",
      key: "updateAt",
      render: (value: string) => <BaseText>{dateFormatter(value, true)}</BaseText>,
    },
    {
      title: "กิจกรรม",
      dataIndex: "activity",
      key: "activity",
      render: (value: string) => <BaseText>{value}</BaseText>,
    },
    {
      title: "สถานะ",
      dataIndex: "statusAfter",
      key: "statusAfter",
      render: (value: string) => (
        <StatusBadge label={MAP_STATUS_WORD[value]} bgColor={MAP_STATUS_COLOR[value]} />
      ),
    },
    {
      title: "ยอดแต้มที่ได้รับ",
      dataIndex: "point",
      key: "point",
      render: (value: number, row: any) => (
        <>
          <BaseText
            style={{ color: row.statusAfter === "CANCEL" ? color.placeholder : color.Text1 }}
          >
            {row.statusAfter !== "APPROVE" && "≈"} {numberFormatter(value, 2)}
          </BaseText>
          <DescText>แต้ม</DescText>
        </>
      ),
    },
    {
      title: "ผู้ใช้งาน",
      dataIndex: "updateBy",
      key: "updateBy",
      render: (value: string, row: any) => (
        <>
          <BaseText>{value || "-"}</BaseText>
          <DescText>{row.userType}</DescText>
        </>
      ),
    },
  ];

  return (
    <CustomTableWrapper style={{ marginTop: 22 }}>
      <Text fontSize={16} fontWeight={700}>
        ประวัติทำรายการ
      </Text>
      <div style={{ marginTop: 22 }}>
        <Table
          scroll={{ y: 600 }}
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={{
            emptyText: (
              <div style={{ display: "flex", justifyContent: "center", padding: "6%" }}>
                <Empty
                  image={icon.emptyHistoryPoint}
                  description={
                    <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text3'>
                      ไม่มีรายการประวัติ
                    </Text>
                  }
                />
              </div>
            ),
          }}
        />
      </div>
    </CustomTableWrapper>
  );
}

export default HistoryTab;
