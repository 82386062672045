export interface statisticsGraphEntities {
  countOrder: number;
  date: string;
  sumApprove: number;
  sumQuantity: number;
  sumWaitApprove: number;
  date_trunc?: string;
}
export const statisticsGraphEntities_INIT: statisticsGraphEntities = {
  countOrder: 0,
  date: "",
  sumApprove: 0,
  sumQuantity: 0,
  sumWaitApprove: 0,
  date_trunc: "",
};
export interface summaryCampaignEntities {
  countCampaignAll: number;
  countCampaignCurrent: number;
  countProgramAll: number;
  countProgramCurrent: number;
}
export const summaryCampaignEntities_INIT: summaryCampaignEntities = {
  countCampaignAll: 0,
  countCampaignCurrent: 0,
  countProgramAll: 0,
  countProgramCurrent: 0,
};
export interface summaryStatisticsEntities {
  countCampaign: number;
  countOrder: number;
  countShopDealer: number;
  countShopSubDealer: number;
  sumAllShop: number;
  sumPointApprove: number;
  sumPointCancel: number;
  sumPointWaitApprove: number;
  sumQuantity: number;
}
export const summaryStatisticsEntities_INIT: summaryStatisticsEntities = {
  countCampaign: 0,
  countOrder: 0,
  countShopDealer: 0,
  countShopSubDealer: 0,
  sumAllShop: 0,
  sumPointApprove: 0,
  sumPointCancel: 0,
  sumPointWaitApprove: 0,
  sumQuantity: 0,
};
export interface ReportCRMEntities {
  statisticsGraph: statisticsGraphEntities[];
  summaryCampaign: summaryCampaignEntities;
  summaryStatistics: summaryStatisticsEntities;
}
export const ReportCRMEntities_INIT: ReportCRMEntities = {
  statisticsGraph: [statisticsGraphEntities_INIT],
  summaryCampaign: summaryCampaignEntities_INIT,
  summaryStatistics: summaryStatisticsEntities_INIT,
};
export interface dataGraphEntities {
  countOrder: number;
  date: string;
  sumApprove: number;
  sumQuantity: number;
  sumWaitApprove: number;
}
export const dataGraphEntities_INIT: dataGraphEntities = {
  countOrder: 0,
  date: "",
  sumApprove: 0,
  sumQuantity: 0,
  sumWaitApprove: 0,
};
