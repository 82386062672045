import React, { useEffect, useState } from "react";
import { CardContainer } from "../../components/Card/CardContainer";
import { Col, Row, Spin, Tabs } from "antd";
import Text from "../../components/Text/Text";
import PageTitleNested from "../../components/PageTitle/PageTitleNested";
import { useNavigate } from "react-router-dom";
import { FlexCol } from "../../components/Container/Container";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import Button from "../../components/Button/Button";
import icon from "../../resource/icon";
import { color } from "../../resource";
import DetailTab from "./TabsDetail/DetailTab";
import HistoryTab from "./TabsDetail/HistoryTab";
import {
  DownloadPDFPreparation,
  PreparationShipmentById,
  PreparationShipmentHistory,
} from "../../datasource/PreparationDatasource";
import {
  DetailPreparationShipmentEntities,
  DetailPreparationShipmentEntities_INIT,
  HistoryPreparationEntities,
  HistoryPreparationEntities_INIT,
} from "../../entities/PreparationShipmentEntity";
import { STATUS_DO_WORD, STATUS_DO_WORD_COLOR } from "../../definitions/statusDO";

function DetailDO() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState<HistoryPreparationEntities[]>([
    HistoryPreparationEntities_INIT,
  ]);
  const [detail, setDetail] = useState<DetailPreparationShipmentEntities>(
    DetailPreparationShipmentEntities_INIT,
  );
  const preparationShipmentId = window.location.pathname.split("/")[2];

  const downloadPDF = async () => {
    try {
      const payload = {
        preparationShipmentId: Array.isArray(preparationShipmentId)
          ? preparationShipmentId
          : [preparationShipmentId],
      };
      const res = await DownloadPDFPreparation(payload);
      if (!res) {
        throw new Error("No data received for the PDF download.");
      }
      const blob = new Blob([res], { type: "application/pdf" });
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `PDO_${detail.doNo}_${detail.soNo}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Failed to download PDF:", error);
    }
  };
  useEffect(() => {
    getDetailPreparation();
    getHistoryPreparation();
  }, []);

  const getDetailPreparation = async () => {
    setLoading(true);
    try {
      await PreparationShipmentById(preparationShipmentId).then((res) => {
        setDetail(res);
      });
    } catch (error) {
      console.log("err preparation-shipment-by-id", error);
    } finally {
      setLoading(false);
    }
  };
  const getHistoryPreparation = async () => {
    setLoading(true);
    try {
      await PreparationShipmentHistory(preparationShipmentId).then((res) => {
        setHistoryData(res);
      });
    } catch (error) {
      console.log("err preparation-shipment-history", error);
    } finally {
      setLoading(false);
    }
  };
  const PageTitle = () => {
    return (
      <PageTitleNested
        title={detail.doNo}
        showBack
        onBack={() => navigate(-1)}
        extra={
          <FlexCol align='end'>
            <Row>
              <Col style={{ display: "flex", alignItems: "center", paddingRight: 16 }}>
                <Text
                  level={3}
                  fontWeight={700}
                  style={{ color: STATUS_DO_WORD_COLOR[detail.status] }}
                >
                  {STATUS_DO_WORD[detail.status]}
                </Text>
              </Col>
              {detail.status === "WAITING" && (
                <Col style={{ borderLeft: "1px solid #dcdcdc", paddingLeft: 16 }}>
                  <Button
                    title='ดาวน์โหลดเอกสารเตรียมจัดส่ง'
                    icon={
                      <img
                        src={icon.downloadFileIcon}
                        style={{ width: 22, height: 26, color: color.BK, paddingBottom: 4 }}
                      />
                    }
                    typeButton='primary-light'
                    onClick={() => downloadPDF()}
                  />
                </Col>
              )}
            </Row>
          </FlexCol>
        }
        customBreadCrumb={
          <BreadCrumb
            data={[
              {
                text: "รายการเตรียมจัดส่ง",
                path: -1,
              },
              { text: "รายละเอียดรายการเตรียมจัดส่ง", path: "" },
            ]}
          />
        }
      />
    );
  };
  const handleTabChange = (key: string) => {
    if (key === "HISTORY") {
      getHistoryPreparation();
    }
  };
  const tabsData: { key: string; label: React.ReactNode; children?: JSX.Element | undefined }[] = [
    {
      key: "DETAIL",
      label: (
        <Text fontSize={14} fontWeight={400}>
          รายละเอียด
        </Text>
      ),
      children: loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Spin size='large' />
        </div>
      ) : (
        <DetailTab data={detail} newFetch={() => getDetailPreparation()} />
      ),
    },
    {
      key: "HISTORY",
      label: (
        <Text fontSize={14} fontWeight={400}>
          ประวัติทำรายการ
        </Text>
      ),
      children: loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Spin size='large' />
        </div>
      ) : (
        <HistoryTab data={historyData} />
      ),
    },
  ];

  return (
    <CardContainer>
      <PageTitle />
      <div style={{ padding: 22 }}>
        <Tabs items={tabsData} onChange={handleTabChange} />
      </div>
    </CardContainer>
  );
}

export default DetailDO;
