import React, { useState } from "react";
import Modal from "./Modal";
import Button from "../Button/Button";
import Text from "../Text/Text";
import { Col, Form, Row } from "antd";
import TextArea from "../Input/TextArea";

interface Props {
  visible: boolean;
  onCancel: () => void;
  callBack: (val) => void;
  status: string;
}

function ModalUpdateStatusDO({ visible, onCancel, callBack, status }: Props) {
  const [form] = Form.useForm();
  const [reason, setReason] = useState<string | undefined>(undefined);

  const onFinish = () => {
    const values = form.getFieldsValue();
    callBack(values);
    form.resetFields();
  };
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      maskClosable={false}
      width={status === "WAITING" ? 300 : 500}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: status === "WAITING" ? "center" : "start",
          padding: "0px 0px 22px 0px",
        }}
      >
        <Text align='left' fontWeight={700} fontSize={24}>
          {status === "WAITING" ? "ยืนยันการปรับสถานะ" : "ปรับสถานะรอเตรียมสินค้า"}
        </Text>
        {status === "SUCCESS" && (
          <Text align='left' fontWeight={400} fontFamily='Sarabun' fontSize={16}>
            โปรดระบุเหตุผลและยืนยันการปรับสถานะ
          </Text>
        )}
      </div>
      {status === "SUCCESS" && (
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name='reason'
            rules={[
              {
                required: true,
                message: (
                  <Text fontFamily='Sarabun' fontSize={12} fontWeight={400} color='error'>
                    กรุณาระบุเหตุผลที่ปรับสถานะ
                  </Text>
                ),
              },
            ]}
          >
            <TextArea
              onChange={(val) => setReason(val.target.value)}
              placeholder='ระบุเหตุผลที่ปรับสถานะ'
            />
          </Form.Item>
        </Form>
      )}

      <Row style={{ marginTop: 22 }}>
        <Col span={12} style={{ paddingRight: 5 }}>
          <Button
            typeButton='primary-light'
            title='ยกเลิก'
            style={{ width: "100%" }}
            onClick={handleCancel}
          />
        </Col>
        <Col span={12} style={{ paddingLeft: 5 }}>
          <Button
            disabled={status === "SUCCESS" && !reason}
            typeButton={status === "SUCCESS" && !reason ? "disabled" : "primary"}
            title='ยืนยัน'
            style={{ width: "100%" }}
            onClick={() => (status === "SUCCESS" ? form.submit() : callBack(""))}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default ModalUpdateStatusDO;
