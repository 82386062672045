import React from "react";
import Modal from "./Modal";
import Button from "../Button/Button";
import Text from "../Text/Text";
import { Col, Form, Row } from "antd";

interface Props {
  visible: boolean;
  onCancel: () => void;
  handleSubmit: () => void;
  isCheck: boolean;
}

function ModalSaleCheck({ visible, onCancel, handleSubmit, isCheck }: Props) {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal visible={visible} onCancel={handleCancel} maskClosable={false} width={500}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          padding: "0px 0px 22px 0px",
        }}
      >
        <Text align='left' fontWeight={700} fontSize={24}>
          {isCheck ? "ยกเลิกการตรวจสอบใบขน" : "ตรวจสอบและยืนยันความถูกต้องของใบขน"}
        </Text>
        <Text align='left' fontWeight={400} fontFamily='Sarabun' fontSize={16} color='Text3'>
          โปรดตรวจสอบรายละเอียดข้อมูลเอกสารก่อนยืนยัน
        </Text>
      </div>
      <Row style={{ marginTop: 22 }}>
        <Col span={24} style={{ paddingLeft: 5, display: "flex", justifyContent: "end" }}>
          <Button
            typeButton={isCheck ? "border-danger" : "primary-light"}
            title='ยกเลิก'
            style={{ width: "max-content" }}
            onClick={handleCancel}
          />
          <Button
            typeButton={isCheck ? "danger" : "primary"}
            title='ยืนยัน'
            style={{ width: "max-content", marginLeft: 10 }}
            onClick={handleSubmit}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default ModalSaleCheck;
