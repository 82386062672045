export interface PreparationShipmentEntities {
  preparationShipmentId: string;
  orderDate: string;
  doNo: string;
  soNo: string;
  customerName: string;
  shipmentAddress: string;
  amountProduct: number;
  status: string;
  createAt: string;
  updateAt: string;
  company: string;
}
export const PreparationShipmentEntities_INIT: PreparationShipmentEntities = {
  preparationShipmentId: "",
  orderDate: "",
  doNo: "",
  soNo: "",
  customerName: "",
  shipmentAddress: "",
  amountProduct: 0,
  status: "",
  createAt: "",
  updateAt: "",
  company: "",
};

export interface PreparationShipmentProdEntities {
  preparationShipmentProductId: string;
  productId: number;
  productName: string;
  productCode: string;
  commonName: string;
  pendingQuantity: string;
  productRequest: string;
  unitName: string;
  pendingQuantitySubUnit: string;
  productRequestSubUnit: string;
  subUnitName: string;
  preparationShipmentId: string;
  createAt: string;
  updateAt: string;
  productImage: string;
}
export const PreparationShipmentProdEntities_INIT: PreparationShipmentProdEntities = {
  preparationShipmentProductId: "",
  productId: 0,
  productName: "",
  productCode: "",
  commonName: "",
  pendingQuantity: "",
  productRequest: "",
  unitName: "",
  pendingQuantitySubUnit: "",
  productRequestSubUnit: "",
  subUnitName: "",
  preparationShipmentId: "",
  createAt: "",
  updateAt: "",
  productImage: "",
};
export interface DetailPreparationShipmentEntities {
  preparationShipmentId: string;
  orderDate: string;
  doNo: string;
  soNo: string;
  saleName: string;
  cusComId: number;
  customerName: string;
  customerNo: string;
  customerZone: string;
  customerTel: string;
  address: string;
  shipmentAddress: string;
  reason: string | null;
  amountProduct: number;
  status: string;
  createAt: string;
  updateAt: string;
  updateBy: string;
  company: string;
  preparationShipmentProduct: PreparationShipmentProdEntities[];
}
export const DetailPreparationShipmentEntities_INIT: DetailPreparationShipmentEntities = {
  preparationShipmentId: "",
  orderDate: "",
  doNo: "",
  soNo: "",
  saleName: "",
  cusComId: 0,
  customerName: "",
  customerNo: "",
  customerZone: "",
  customerTel: "",
  address: "",
  shipmentAddress: "",
  reason: "",
  amountProduct: 0,
  status: "",
  createAt: "",
  updateAt: "",
  updateBy: "",
  company: "",
  preparationShipmentProduct: [PreparationShipmentProdEntities_INIT],
};
export interface HistoryPreparationEntities {
  createAt: string;
  preparationShipmentHistoryId: string;
  preparationShipmentId: string;
  reason: string;
  statusAfter: string;
  updateAt: string;
  updatedBy: string;
}
export const HistoryPreparationEntities_INIT: HistoryPreparationEntities = {
  createAt: "",
  preparationShipmentHistoryId: "",
  preparationShipmentId: "",
  reason: "",
  statusAfter: "",
  updateAt: "",
  updatedBy: "",
};
